export const APP_CONFIG = {
    "NAVBAR_FEATURES_LINK": "Features",
    "NAVBAR_PRICING_LINK": "Pricing",
    "NAVBAR_FAQ_LINK": "FAQ",
    "NAVBAR_CONTACT_LINK": "Contact Us",
    "NAVBAR_LOGOUT_LINK": "Logout",
    "NAVBAR_SUBSCRIPTIONS_BUTTON": "Subscriptions",
    "NAVBAR_MANAGE_LICENSES_BUTTON": "Manage Licenses",
    "NAVBAR_REPORTS_BUTTON": "Reports",
    "BUY_NOW_BUTTON": "Buy Now",
    "BANNER_TITLE_1": "Take the Lead on Your Data",
    "BANNER_TITLE_2": "Create powerful reports with only what you need.",
    "BANNER_TITLE_3": "Filter and Highlight to Regain Control",
    "ADD_TO_TRELLO_BUTTON": "Add to Trello",
    "HOME_FEATURES_SECTION_TITLE": "KEY FEATURES",
    "HOME_FEATURES_SECTION_SUBTITLE": "Designed to expedite work for project managers and industry leaders",
    "HOME_FEATURES_SECTION_LEARN_MORE_LINK": "Learn more",
    "HOME_PRICING_SECTION_TITLE": "PRICING",
    "HOME_CONTACT_SECTION_TITLE": "At your fingertips",
    "HOME_CONTACT_SECTION_NEWSLETTER_TITLE": "Newsletter",
    "HOME_CONTACT_SECTION_SUBTITLE_1": "Subscribe to our Newsletter",
    "HOME_CONTACT_SECTION_SUBTITLE_2": "Stay up to date with our development",
    "FOOTER_COPYRIGHT": "Copyright @",
    "DATA_HIGHLIGHTER": "Data Highlighter",
    "FAQ_PAGE_TITLE": "Frequently Asked Question",
    "FAQ_PAGE_SUBTITLE": "Frequently Asked Question",
    "LOGIN_PAGE_TITLE": "Login",
    "FORGOT_PASSWORD": "Forgot Password?",
    "CHECKOUT_PAGE_TITLE": "Let’s create an account for you",
    "EMAIL_LABEL": "Email",
    "PASSWORD_LABEL": "Password",
    "NEW_PASSWORD_LABEL": "New Password",
    "CONFIRM_PASSWORD_LABEL": "Confirm Password",
    "SELECT_BUTTON": "Select",
    "I_AGREE_TO_TERMS_OF_SERVICE_AND_PRIVACY_POLICY": "I agree to Terms of Service and Privacy Policy.",
    "NEXT_BUTTON": "Next",
    "LICENSE_MANAGER": "License Manager",
    "SUBSCRIPTIONS": "Subscriptions",
    "EDIT_CANCEL": "Edit/Cancel",
    "RELEASE_LICENCE_KEY": "Release Licence Key",
    "SUBSCRIPTIONS_CARD_USER": "User:",
    "SUBSCRIPTIONS_CARD_BOARD_NAME": "Board Name:",
    "SUBSCRIPTIONS_CARD_LICENSE_KEY": "License Key:",
    "SUBSCRIPTIONS_CARD_PURCHASED_ON": "License Period:",
    "SUBSCRIPTIONS_CARD_PLAN_TYPE": "Plan Type:",
    "SUBSCRIPTIONS_CARD_PLAN_STATUS": "Status",
    "FREE_TO_USE": "Free to use",
    "VIEW_FILTERS_BUTTON": "View Filters",
    "SELECT_REPORTS": "----Select Reports",
    "SHARE_REPORTS_BUTTON": "Share Report",
    "FORGOT_PASSWORD_PAGE_TITLE": "Forgot Password",
    "SUBMIT_BUTTON": "Submit",
    "WANT_TO_TRY_LOGIN_AGAIN": "Want to try login again?",
    "PAYMENT_SUCCESSFULLY_RECEIVED": "Payment Successfully Received",
    "CONFIRMATION_STATEMENT_1": "We send Confirmation and you Account details to Your E-mail",
    "CONFIRMATION_STATEMENT_2": "We send Confirmation E-mail",
    "PAYMENT_FAILED": "Payment Failed",
    "TRY_AGAIN_BUTTON": "Try Again",
    "RESET_YOUR_PASSWORD_PAGE_TITLE": "Reset Your Password",
    "FILTERSTAB_LIST_BUTTON": "List",
    "FILTERSTAB_FIELDS_BUTTON": "Fields",
    "FILTERSTAB_MEMBERS_BUTTON": "Members",
    "FILTERSTAB_LABELS_BUTTON": "Labels",
    "FILTERSTAB_SEARCH_BUTTON": "Search",
    "FILTERSTAB_GENERAL_BUTTON": "General",
    "NO_HIGHLIGHTS_FOUND": "No highlights found",
    "NO_LIST_ITEMS_FOUND": "No list items found",
    "NO_FIELDS_FOUND": "No fields found",
    "NO_MEMBERS_FOUND": "No members found",
    "NO_LABELS_FOUND": "No labels found",
    "FILTERSTAB_LIST_SECTION_TITLE": "Selected list items in the Report",
    "FILTERSTAB_FIELDS_SECTION_TITLE": "Selected fields and rearrange them in desired order expect in the Report",
    "FILTERSTAB_MEMBERS_SECTION_TITLE": "Selected the members to be included in the Report.",
    "FILTERSTAB_LABELS_SECTION_TITLE": "Selected the labels to be included in the report.",
    "CONDITION_TITLE": "Condition",
    "HIGHLIGHTSTAB_FIELD_TITLE": "Field",
    "HIGHLIGHTSTAB_VALUE_TITLE": "Value",
    "HIGHLIGHTSTAB_CELL_COLOR_TITLE": "Cell Color",
    "HIGHLIGHTSTAB_CELL_BORDER_TITLE": "Cell Border",
    "HIGHLIGHTSTAB_TEXT_COLOR_TITLE": "Text Color",
    "HIGHLIGHTSTAB_SELECT_ICON_TITLE": "Select Icon",
    "FILTERSTAB_TITLE": "Filters",
    "HIGHLIGHTSTAB_TITLE": "Highlights",
    "AND_BUTTON": "AND",
    "OR_BUTTON": "OR",
    "COLOR_BUTTON": "Color",
    "ICON_BUTTON": "Icon",
    "SEARCH_FOR_THE_KEYWORD": "Search for the Keyword",
    "SEARCH_FOR_THE_2ND_KEYWORD": "Search for the 2nd Keyword",
    "START_DATES_BETWEEN": "Start dates between",
    "DUE_DATES_BETWEEN": "Due dates between",
    "PRIVACY_POLICY": "Privacy Policy",
    'TERMS_SERVICE':'Terms of Service',
    "RENEW_SUBSCRIPTION": "Renew subscription",
}
export const TablesKeys = [
    { id: 100, value: 'strips', label: '' },
    { id: 9, value: 'dateLastActivity', label: 'DateLast Activity' },
    { id: 27, value: 'name', label: 'Card Title' },
    { id: 10, value: 'desc', label: 'Description' },
    { id: 17, value: 'idLabels', label: 'Labels Name' },
    { id: 18, value: 'idList', label: 'List Name' },
    { id: 19, value: 'idMembers', label: 'Members Name' },
    { id: 15, value: 'idBoard', label: 'Board Name' },
    { id: 1, value: 'idShort', label: 'Card ID' },
    { id: 20, value: 'startDate', label: 'Start Date' },
    { id: 21, value: 'due', label: 'Due Date' },
    { id: 8, value: 'dueComplete', label: 'Due Complete' },
    { id: 30, value: 'shortUrl', label: 'Card Url' },
    { id: 31, value: 'subscribed', label: 'Subscribed' },
]