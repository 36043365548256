import React, { useContext, useRef } from 'react';
import { DataContext } from '../components/context';
import Banner from '../components/home/Banner';
import Contact from '../components/home/Contact';
import Pricing from '../components/home/Pricing';
import Services from '../components/home/Services';
import Footer from '../components/layout/Footer';
import Navbar from '../components/layout/Navbar';

const Home = () => {
    const Context = useContext(DataContext);
    const scrollToServices = (ref) =>
        window.scrollTo({
            top: ref?.current?.offsetTop,
            behavior: "smooth"
        });

    const scrollToPricing = (ref) =>
        window.scrollTo({
            top: ref?.current?.offsetTop,
            behavior: "smooth"
        });

    const scrollToContact = (ref) =>
        window.scrollTo({
            top: ref?.current?.offsetTop,
            behavior: "smooth"
        });
    const element1 = useRef();
    const element2 = useRef();
    const element3 = useRef();

    return (
        <>
            <Navbar onServicesClick={() => scrollToServices(element1)} onPricingClick={() => scrollToPricing(element2)} onContactClick={() => scrollToContact(element3)} userData={Context?.user} />
            <Banner />
            <Services reference={element1} />
            <Pricing reference={element2} />
            <Contact reference={element3} />
            <Footer />
        </>
    );
}

export default Home;
