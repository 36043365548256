import React, { useEffect, useMemo, useState, } from 'react';
import List from '../../components/FilterComponent/List';
import Lables from '../../components/FilterComponent/Lables';
import Member from '../../components/FilterComponent/Member';
import Fields from '../../components/FilterComponent/Fields';
import Search from '../../components/FilterComponent/Search';
import General from '../../components/FilterComponent/General';
import { APP_CONFIG } from '../../utils/StaticData';

const FiltersTab = ({ filters }) => {
    console.log("FiltersTab filters", filters);
    return (
        <div className="filters">
            <div className="d-flex align-items-start">
                <div className="nav flex-column nav-pills border-end pt-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button className="nav-link active" id="v-pills-list-tab" data-bs-toggle="pill" data-bs-target="#v-pills-list" type="button" role="tab" aria-controls="v-pills-list" aria-selected="true">{APP_CONFIG.FILTERSTAB_LIST_BUTTON}</button>
                    <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">{APP_CONFIG.FILTERSTAB_FIELDS_BUTTON}</button>
                    <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">{APP_CONFIG.FILTERSTAB_MEMBERS_BUTTON}</button>
                    <button className="nav-link" id="v-pills-Lables-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Lables" type="button" role="tab" aria-controls="v-pills-Lables" aria-selected="false">{APP_CONFIG.FILTERSTAB_LABELS_BUTTON}</button>
                    <button className="nav-link" id="v-pills-search-tab" data-bs-toggle="pill" data-bs-target="#v-pills-search" type="button" role="tab" aria-controls="v-pills-search" aria-selected="false">{APP_CONFIG.FILTERSTAB_SEARCH_BUTTON}</button>
                    <button className="nav-link" id="v-pills-general-tab" data-bs-toggle="pill" data-bs-target="#v-pills-general" type="button" role="tab" aria-controls="v-pills-general" aria-selected="false">{APP_CONFIG.FILTERSTAB_GENERAL_BUTTON}</button>
                </div>
                <div className="tab-content p-4 w-100 scrollable-section" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-list" role="tabpanel" aria-labelledby="v-pills-list-tab">
                        <List listData={filters?.list} />
                    </div>
                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><Fields data={filters?.fields} /></div>
                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <Member memberData={filters?.member} />
                    </div>
                    <div className="tab-pane fade" id="v-pills-Lables" role="tabpanel" aria-labelledby="v-pills-Lables-tab"> <Lables data={filters?.lables} /></div>
                    <div className="tab-pane fade" id="v-pills-search" role="tabpanel" aria-labelledby="v-pills-search-tab"> <Search data={filters?.search} /></div>
                    <div className="tab-pane fade" id="v-pills-general" role="tabpanel" aria-labelledby="v-pills-general-tab"> <General Generaldata={filters?.general} /></div>
                </div>
            </div>
        </div>
    );
}

export default FiltersTab;
