import React, { Component, useEffect, useState } from 'react';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import FiltersTab from './Tabs/FiltersTab';
import HighlighterTab from './Tabs/HighlighterTab';
import { Modal } from 'react-responsive-modal';
import { APP_CONFIG } from '../utils/StaticData';
const UserRefModal = ({ open, onClose, UID }) => {
    const [data, setdata] = useState({})

    const getdata = async () => {
        const res = await NetworkClient.post(BASE_URL + "user-preference-details", {
            unique_board_subscription_id: UID
        })
        console.log("user-preference-details", res?.data?.data?.userPref);
        setdata(res?.data?.data?.userPref)
    }
    useEffect(() => {
        if (open) {
            getdata()
        }
        return function cleanUp() {
            setdata({})
        }
    }, [open])
    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={true} classNames={{ modal: "filter-modal p-0 text-start" }}>
            {/* <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-body p-0"> */}
            <div className="header-tabs">
                <nav className="sticky-top bg-white">
                    <div className="nav nav-tabs px-3" id="nav-tab" role="tablist">
                        <button className="nav-link px-2 active" id="nav-Filters-tab" data-bs-toggle="tab" data-bs-target="#nav-Filters" type="button" role="tab" aria-controls="nav-Filters" aria-selected="true">{APP_CONFIG.FILTERSTAB_TITLE}</button>
                        <button className="nav-link px-2" id="nav-Highlights-tab" data-bs-toggle="tab" data-bs-target="#nav-Highlights" type="button" role="tab" aria-controls="nav-Highlights" aria-selected="false">{APP_CONFIG.HIGHLIGHTSTAB_TITLE}</button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-Filters" role="tabpanel" aria-labelledby="nav-Filters-tab"><FiltersTab filters={data?.filter} /></div>
                    <div className="tab-pane fade" id="nav-Highlights" role="tabpanel" aria-labelledby="nav-Highlights-tab"><HighlighterTab highlighter={data?.highiler} /></div>
                </div>

                {/* </div>
                    </div>
                </div> */}
            </div>
        </Modal>
    );
}

export default UserRefModal;
