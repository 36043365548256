import React from 'react';
import { APP_CONFIG } from '../../utils/StaticData';

const List = ({ listData }) => {
    return (
        <div>
            <h6 className="mb-3 fw-normal">{APP_CONFIG.FILTERSTAB_LIST_SECTION_TITLE}</h6>
            {listData?.length === 0 ? (
                <div className="d-flex justify-content-center ptb-120">
                    <span>{APP_CONFIG.NO_LIST_ITEMS_FOUND}</span>
                </div>
            ) : (
                    <>
                        {listData?.map((item, i) => (
                            <div
                                key={i}
                                className="d-flex align-items-center mb-1 pt-2">
                                <input className="form-check-input custom-checkbox me-3 my-0"
                                    type="checkbox"
                                    checked={true}
                                    disabled
                                />
                                <label
                                    className="form-check-label custom"
                                    htmlFor="flexCheckDefault">
                                    {item.name}
                                </label>
                            </div>
                        ))}
                    </>
                )}

        </div>
    );
}

export default List;
