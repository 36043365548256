import React from 'react';
import { APP_CONFIG } from '../../utils/StaticData';
const Lables = ({ data }) => {
    return (
        <div className="labels">
            <h6 className="mb-4 fw-normal">{APP_CONFIG.FILTERSTAB_LABELS_SECTION_TITLE} </h6>
            <ul className="nav nav-pills mb-3 align-items-center" id="pills-tab" role="tablist">
                <h6 className="mb-0 fw-normal text-black">{APP_CONFIG.CONDITION_TITLE}</h6>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${data?.condition == 'and' ? 'active' : ""} `}
                        style={{ cursor: 'default' }}
                        type="button"
                    >{APP_CONFIG.AND_BUTTON}</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${data?.condition == 'or' ? 'active' : ""} `}
                        style={{ cursor: 'default' }}
                        type="button"
                    >{APP_CONFIG.OR_BUTTON}</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" >
                    {data?.lableList?.length === 0 ? (
                        <div className="d-flex justify-content-center ptb-120">
                            <span>{APP_CONFIG.NO_LABELS_FOUND}</span>
                        </div>
                    ) : (
                        <>
                            {data?.lableList && data?.lableList?.map((data, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="d-flex align-items-center mb-1 pt-2">
                                        <input className="form-check-input custom-checkbox me-3 my-0"
                                            type="checkbox"
                                            id={data.id}
                                            checked={true}
                                            disabled
                                        />
                                        <div className="form-check-label w-100"                       >
                                            <label style={{ backgroundColor: data?.color }} className="form-check-label custom">{data?.name}</label>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Lables;
