import React from "react";
import LoadingOverlay from "react-loading-overlay";
const Loader = ({loader}) => {
    console.log("loader =>", loader);
        return (
            loader ? (
                <div>
                    <LoadingOverlay
                        active={loader}
                        spinner
                        styles={{
                            wrapper: {
                                height: "100%",
                                width: "100%",
                                position: "fixed",
                                zIndex: "50000",
                                top: "0",
                                background: "rgba(255, 255, 255, 0.5)"
                            }
                        }}
                    ></LoadingOverlay>
                </div>
            ) : (
                    ""
                )
        );
    }

export default Loader;