import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export const ListingLoader = () => {
    const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    return (
        <div className="container">
            {/* <div className='p-2 mb-2 mt-2' style={{ minHeight: '64px' }}>
                <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
                    <Skeleton count={1} className="w-100" />
                </SkeletonTheme>
            </div> */}
            <div class="table-responsive p-2">
                <table class="table  table-striped bg-white" >
                    <tbody >
                        {array?.map((e) => {
                            return (
                                <tr >
                                    <td>
                                        <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
                                            <Skeleton count={1} className="w-100" />
                                        </SkeletonTheme>
                                    </td>
                                    <td>
                                        <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
                                            <Skeleton count={1} className="w-100" />
                                        </SkeletonTheme>
                                    </td>
                                    <td>
                                        <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
                                            <Skeleton count={1} className="w-100" />
                                        </SkeletonTheme>
                                    </td>
                                    <td>
                                        <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
                                            <Skeleton count={1} className="w-100" />
                                        </SkeletonTheme>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const SubscriptionsLoader = () => {
    return (
        <SkeletonTheme color="#bfbfbf" highlightColor="#f2f2f2">
            <div className="subscriptions bg-white px-3 py-4">
                <div className="d-flex align-items-center mb-18">
                    <div className="col-5">
                        <Skeleton count={1} style={{ width: '40%', }} />
                    </div>
                    <div className="col-7">
                        <Skeleton count={1} style={{ width: '70%', }} />
                    </div>
                </div>
                <div className="d-flex align-items-center mb-18">
                    <div className="col-5">
                        <Skeleton count={1} style={{ width: '70%', }} />
                    </div>
                    <div className="col-7">
                        <Skeleton count={1} style={{ width: '100%', }} />
                    </div>
                </div>
                <div className="d-flex align-items-center mb-18">
                    <div className="col-5">
                        <Skeleton count={1} style={{ width: '60%', }} />
                    </div>
                    <div className="col-7">
                        <Skeleton count={1} style={{ width: '60%', }} />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="col-5">
                        <Skeleton count={1} style={{ width: '40%', }} />
                    </div>
                    <div className="col-7">
                        <Skeleton count={1} style={{ width: '70%', }} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}