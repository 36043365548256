export const getLabelColor = (color) => {
    switch (color) {
        case 'black':
            return '#344563'
        case 'green':
            return '#61bd4f'
        case 'orange':
            return '#ff9f1a'
        case 'yellow':
            return '#f2d600'
        case 'red':
            return '#eb5a46'
        case 'purple':
            return '#c377e0'
        case 'blue':
            return '#0079bf'
        case 'sky':
            return '#00c2e0'
        case 'lime':
            return '#51e898'
        case 'pink':
            return '#ff78cb'
        default:
            return '#000'
    }
}