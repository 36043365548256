import React from 'react';
import { NavLink } from "react-router-dom";
import ServicesData from '../../utils/ServicesData';
import { APP_CONFIG } from '../../utils/StaticData';

const Services = (props) => {
    // console.log("this.props >", props);

    return (
        <section id='Features' className="welcome-services amazing-features ptb-140 bg-white" ref={props?.reference} >
            <div className="container">
                <div className="section-title pt-160 pt-0 mb-0">
                    <h2 className="fs-40 mb-4">{APP_CONFIG.HOME_FEATURES_SECTION_TITLE}</h2>
                    <h4 className="color-737373">{APP_CONFIG.HOME_FEATURES_SECTION_SUBTITLE}</h4>
                </div>

                <div className="row">
                    {ServicesData?.map((data, i) => {
                        return (
                            <div className="col-lg-4 col-md-6 mb-34 px-xl-4">
                                <div className="single-services amazing-features h-100">
                                    <img src={data?.img} alt="sheets" />
                                    <h4 className="my-4 lh-base fw-bold">{data?.title}</h4>
                                    <p>{data?.desc}</p>
                                    <NavLink to="/faq" className="mt-4">{APP_CONFIG.HOME_FEATURES_SECTION_LEARN_MORE_LINK}
                                    </NavLink>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}
export default Services;
