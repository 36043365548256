import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';

const ProcessErrorAndRespond = ({ Error }) => {
    const [model, setModal] = useState(false);
    const [Title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    useEffect(() => {
        console.log("Error?.response", Error);
        if (Error && Error?.response) {
            if (Error?.response?.status == 401) {
                setModal(true);
                setTitle("Error !!");
                setMessage("You are not authorized to perform this action")
            } else if (Error?.response?.status == 404) {
                setModal(true);
                setTitle("Error !!");
                setMessage("The requested request is unknown to server")
            } else if (Error?.response?.status == 500) {
                setModal(true);
                setTitle("Server Error !!");
                setMessage("Please try after some time...")
            }
        } else if (Error?.code == 401) {
            setModal(true);
            setTitle("Error !!");
            setMessage(Error?.data)
        } else if (Error?.code == 400) {
            setModal(true);
            setTitle("Error !!");
            setMessage(Error?.message)
        }
    }, [Error])
    return (
        <Modal showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false} open={model} onClose={() => {
            setModal(false);
            setMessage('');
            setTitle('')
        }} center classNames={{ modal: "py-4" }}>
            <h4 className={`modal-heading fw-bold text-danger`}>{Title}</h4>
            <h6 className="mt-3 mb-4">
                {message}
            </h6>
            <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary w-50 py-2" onClick={() => {
                    setModal(false);
                    setMessage('');
                    setTitle('')
                }}>OK</button>
            </div>
        </Modal >
    )
}

export default ProcessErrorAndRespond;