import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import TitleTag from '../components/TitleTag';
import queryString from 'query-string';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { APP_CONFIG } from '../utils/StaticData';
const CheckoutSuccess = () => {
    const location = useLocation();
    const history = useHistory();
    const QString = queryString?.parse(location?.search);
    const [isLoading, setLoader] = useState(true);
    const checkSession = async () => {
        setLoader(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "check-session-expiry", {
                checkout_session_id: QString?.session_id
            })
            console.log("check-session-expiry", res);
            if (res?.data?.data) {
                history.push('/')
            } else {
                setLoader(false);
            }
        } catch (err) {
            console.log("check-session-expiry err", err);
            setLoader(false);
        }
    }
    useEffect(() => {
        checkSession()
    }, [])
    return (
        <>
            <TitleTag title={"CheckoutSuccess"} />
            <section className="faq-area pt-160 pb-0">
                <div className="container h-100 ptb-100 ">
                    {!isLoading &&
                        <div className="section-title mb-5">
                            <h2 className="pb-2 mb-0">{APP_CONFIG.PAYMENT_SUCCESSFULLY_RECEIVED}</h2>
                            {QString.System_generated_password == 1 ?
                                <h5 className="pt-1">{APP_CONFIG.CONFIRMATION_STATEMENT_1}  </h5> :
                                <h5 className="pt-1">{APP_CONFIG.CONFIRMATION_STATEMENT_2}  </h5>

                            }
                            <div className='ptb-80'>
                                <hr className="horizontal-rule m-auto" />
                            </div>
                            {localStorage.getItem('user_token') ?
                                <NavLink to="/subscriptions">
                                    <button type="button" className="btn btn-primary">Go to Subscriptions page</button>
                                </NavLink>
                                :
                                <NavLink to="/login">
                                    <button type="button" className="btn btn-primary">Go to Log In page</button>
                                </NavLink>
                            }
                        </div>
                    }</div>
            </section>
            {/* <Footer /> */}
        </>
    );
}

export default CheckoutSuccess;