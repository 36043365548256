import React, { useEffect, useState } from 'react';
import Contact from '../components/home/Contact';
import Footer from '../components/layout/Footer';
import TitleTag from '../components/TitleTag';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { APP_CONFIG } from '../utils/StaticData';

const FAQ = () => {

    const [faqs, setFaqs] = useState([]);

    const getFaqs = async ()=>{
        const res = await NetworkClient.get(BASE_URL + "get-frequently-ask-questions");
        const allFaqs = res?.data?.data;
        let allQuestions = [];
        Object.keys(allFaqs).forEach(itm=>{
            allQuestions= allQuestions.concat(allFaqs[itm])
        })
        setFaqs(allQuestions);
    }
    
    useEffect(()=>{
        getFaqs()
    },[])
    
    return (
        <>
            <TitleTag title={"FAQ"} />
            <section className="faq-area pt-160 pb-0">
                <div className="container h-100">
                    <div className="section-title mb-5">
                        <h2 className="pb-2 mb-0">{APP_CONFIG.FAQ_PAGE_TITLE}</h2>
                        <h5 className="pt-1"> {APP_CONFIG.FAQ_PAGE_SUBTITLE}</h5>
                    </div>
                    <div className="row h-100 justify-content-center align-items-center ptb-100 pt-0">
                    {faqs?.map((data, i) => {
                        return (
                            <div className="col-12 col-lg-6 pe-lg-4" key={i}>
                                <div class="mb-4">
                                    <h5>{data.question} </h5>
                                        <p class="pt-1">{data.answers}</p>
                                    </div>
                                </div>
                        )
                    })}
                    </div>
                    <hr className="horizontal-rule m-auto" />
                    <Contact />
                </div>
            </section>
            <Footer />
        </>
    );
}

export default FAQ;