import React, { useEffect, useState } from 'react';
import TitleTag from '../components/TitleTag';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { SubscriptionsLoader } from '../components/SkeletonLoader';
import { NavLink } from "react-router-dom";
import AlertModal from '../components/AlertModal';
import Loader from '../components/shared/Loader';
import ProcessErrorAndRespond from '../components/ProcessErrorAndRespond';
import moment from "moment";
import { APP_CONFIG } from '../utils/StaticData';
const load = [1, 2, 3, 4, 5, 6]
const Subscriptions = () => {
    const [details, setDetails] = useState([])
    const [isLoading, setLoader] = useState(false);
    const [isLoadingOverlay, setLoaderOverlay] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [model, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [err, setErr] = useState({});
    const [message, setMessage] = useState('');
    const getPreducts = async () => {
        setLoader(true);
        try {
            const res = await NetworkClient.get(BASE_URL + "get-user-licence-key-details")
            console.log("licence-key-details", res?.data);
            if (res?.data?.data) {
                setDetails(res?.data?.data)
            }
            setLoader(false);
        } catch (err) {
            console.log("licence-key-details err", err);
            setLoader(false);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const updateBiling = async () => {
        setLoaderOverlay(true);
        try {
            const res = await NetworkClient.get(BASE_URL + "update-billing")
            console.log("update-billing", res?.data);
            window.location = res?.data?.data
            // setDetails(res?.data?.data)
            setLoaderOverlay(false);
        } catch (err) {
            console.log("update-billing err", err);
            setLoaderOverlay(false);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const releaseLicenceKey = async (id) => {
        // setLoader(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "release-licence-key", {
                unique_id: id
            })
            console.log("release-licence-key", res?.data);
            setTitle('Success');
            setMessage(res?.data?.data)
            setModal(true)
        } catch (err) {
            console.log("release-licence-key err", err);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            console.log("else ");
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }

    const handleCopyClick = (copyText) => {
        copyTextToClipboard(copyText)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        getPreducts()
    }, [])
    return (
        <>
            <TitleTag title={"Subscriptions"} />
            <Loader loader={isLoadingOverlay} />
            <ProcessErrorAndRespond Error={err} />
            <section className="licence-manager min-vh-100 bg-F6F6F6">
                <div className="bg-white">
                    <div className="container">
                        <div className="d-block d-sm-flex justify-content-between align-items-center mb-18 align-items-center pb-3 pt-20 text-center">
                            <h5 className="mb-0 pb-2">{APP_CONFIG.SUBSCRIPTIONS}</h5>
                            <div className="py-1">
                                <NavLink
                                    to={{
                                        pathname: "/checkout",
                                        state: { is_migration: 0 }
                                    }}>
                                    <button type="button" className="btn btn-outline-primary me-3">{APP_CONFIG.BUY_NOW_BUTTON}</button>
                                </NavLink>
                                {/* <PaymentPopup /> */}
                                {/* <button type="button" className="btn btn-outline-primary ">Export PDF</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="row">
                        {isLoading ? <>
                            {load?.map((q) => (
                                <div className="col-xl-5 col-md-6 mb-34">
                                    <SubscriptionsLoader />
                                </div>
                            ))}
                        </> :
                            details.length > 0 && details?.map((data, i) => {
                                return (
                                    <div key={i} className="col-xl-5 col-md-6 mb-34">
                                        <div className="subscriptions bg-white px-3 py-4">
                                            <div className="d-flex justify-content-end align-items-end ">
                                                <div class="btn-group">
                                                    {/* <button type="button" className="btn btn-outline-primary dropdown-toggle custom shadow-none" data-bs-toggle="dropdown" aria-expanded="false"> */}
                                                    <svg type="button" data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                    </svg>
                                                    {/* </button> */}
                                                    <ul className="dropdown-menu">
                                                        {(data?.status === "suspended" && data?.type_of_subscription === "Free Trial") ?
                                                            <li>
                                                                <NavLink
                                                                    to={{
                                                                        pathname: "/checkout",
                                                                        state: { is_migration: 1 }
                                                                    }}>
                                                                    <button type="button" className="dropdown-item">{APP_CONFIG.RENEW_SUBSCRIPTION}</button>
                                                                </NavLink>
                                                            </li>
                                                            :
                                                            <li>
                                                                {/* <form action={BASE_URL + "update-billing"} method='GET' > */}
                                                                <button
                                                                    type='button'
                                                                    onClick={() => updateBiling()}
                                                                    className="dropdown-item">{APP_CONFIG.EDIT_CANCEL} </button>
                                                                {/* </form> */}
                                                            </li>
                                                        }
                                                        {data?.licence_active_status == 1 &&
                                                            <li>
                                                                <button
                                                                    type='button'
                                                                    onClick={() => releaseLicenceKey(data?.user_board_subscription?.[0]?.user_subscription_id)}
                                                                    className="dropdown-item">{APP_CONFIG.RELEASE_LICENCE_KEY}</button>
                                                            </li>
                                                        }
                                                        {/* <li><a className="dropdown-item" href="#">Another action</a></li> */}
                                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-18">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_USER} </h6>
                                                </div>
                                                <div className="col-8">
                                                    {data?.trello_username ?
                                                        <h6 className="color-000000 mb-0">{data?.trello_username}</h6>
                                                        :
                                                        <span class="badge rounded-pill bg-primary username-badge">{APP_CONFIG.FREE_TO_USE}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-18">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_BOARD_NAME} </h6>
                                                </div>
                                                <div className="col-8">
                                                    <h6 className="color-000000 mb-0">{data?.user_board_subscription?.[0]?.board_name}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-18">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_LICENSE_KEY} </h6>
                                                </div>
                                                <div className="col-8">
                                                    <h6 className="color-000000 mb-0 text-break lh-base">{data?.licence_key}
                                                        <div class="tooltip">
                                                            <span class="tooltiptext">{isCopied ? 'Copied!' : 'Copy'}</span>
                                                            <img src="/images/copy.png" alt="logo" className="ms-2 pointer" onClick={() => handleCopyClick(data?.licence_key)} />
                                                        </div>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-18">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_PURCHASED_ON} </h6>
                                                </div>
                                                <div className="col-8">
                                                    <h6 className="color-000000 mb-0">{moment(data?.created_at).format('ll')} - {moment(data?.expired_date).format('ll')}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-18">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_PLAN_TYPE}  </h6>
                                                </div>
                                                <div className="col-8">
                                                    <h6 className="color-000000 mb-0">{data?.type_of_subscription[0].toUpperCase() + data?.type_of_subscription.slice(1)}</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="col-4">
                                                    <h6 className="fw-normal color-00000099 mb-0">{APP_CONFIG.SUBSCRIPTIONS_CARD_PLAN_STATUS}  </h6>
                                                </div>
                                                <div className="col-8">
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="color-000000 mb-0">{data?.status[0].toUpperCase() + data?.status.slice(1)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                    </div>
                </div>
            </section>
            <AlertModal
                isOpen={model}
                Title={title}
                message={message}
                closeModal={() => {
                    setModal(false);
                    setMessage('');
                    setTitle('')
                    window.location.reload()
                }} />
        </>
    );
}

export default Subscriptions;
