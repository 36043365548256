import React from 'react';
import { APP_CONFIG } from '../../utils/StaticData';
const Member = ({ memberData }) => {

    return (
        <div className="member">
            <h6 className="mb-4 fw-normal">{APP_CONFIG.FILTERSTAB_MEMBERS_SECTION_TITLE}</h6>
            <ul className="nav nav-pills mb-3 align-items-center" id="pills-tab" role="tablist">
                <h6 className="mb-0 fw-normal text-black">{APP_CONFIG.CONDITION_TITLE}</h6>
                <li className="nav-item" role="presentation">
                    <button
                        style={{ cursor: 'default' }}
                        className={`nav-link ${memberData?.condition == 'and' ? 'active' : ""} `}
                        type="button"
                    >{APP_CONFIG.AND_BUTTON}</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        style={{ cursor: 'default' }}
                        className={`nav-link ${memberData?.condition == 'or' ? 'active' : ""}`}
                        type="button"
                    >{APP_CONFIG.OR_BUTTON}</button>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-AND" role="tabpanel" aria-labelledby="pills-AND-tab">
                    {memberData?.memberList?.length === 0 ? (
                        <div className="d-flex justify-content-center ptb-120">
                            <span>{APP_CONFIG.NO_MEMBERS_FOUND}</span>
                        </div>
                    ) : (
                        <>
                            {memberData?.memberList && memberData?.memberList?.map((data, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="d-flex align-items-center mb-1 pt-2">
                                        <input
                                            className="form-check-input custom-checkbox me-3 my-0"
                                            type="checkbox"
                                            checked={true}
                                        />
                                        <label className="form-check-label custom" htmlFor="flexCheckDefault">{data?.name}</label>
                                    </div>
                                )
                            }
                            )}
                        </>
                    )}
                </div>
                <div className="tab-pane fade" id="pills-OR" role="tabpanel" aria-labelledby="pills-OR-tab">This is some placeholder content the Home tab's associated content. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classes to control the content visibility and styling. You can use it with tabs, pills, and any other .nav-powered navigation.</div>
            </div>
        </div>
    );
}

export default Member;
