import React from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './assets/css/animate.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/custom-mobile-styles.css';
import './assets/css/custom-styles.css';
import './assets/css/icofont.min.css';
import './assets/css/responsive.scss';
import './assets/css/style.scss';
import Layout from './components/layout/Layout';
import Checkout from './pages/Checkout';
import CheckoutFailed from './pages/CheckoutFailed';
import CheckoutSuccess from './pages/CheckoutSuccess';
import ContactUS from './pages/ContactUS';
import FAQ from './pages/FAQ';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Login from './pages/Login';
import Policy from './pages/Policy';
import Report from './pages/Report';
import ResetPassword from "./pages/reset-password/[slug]/index";
import Subscriptions from './pages/Subscriptions';
import Terms from './pages/Terms';
import ViewReport from './pages/ViewReport';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  return (
    localStorage.getItem('user_token') ?
      <Route path={path} component={Component} /> :
      <Redirect to={"/login?redirectTo=" + path} />
  );
};
const PublicRoute = ({ component: Component, path, ...rest }) => {
  return (
    localStorage.getItem('user_token') ?
      <Redirect to="/" /> :
      <Route path={path} component={Component} />

  );
};
ReactDOM.render((
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/" exact component={Home} />
        <PrivateRoute path="/subscriptions" exact component={Subscriptions} />
        <Route path="/contact-us" exact component={ContactUS} />
        <PrivateRoute path="/user-report" exact component={Report} />
        <PublicRoute path="/login" exact component={Login} />
        <Route path="/checkout" exact component={Checkout} />
        <Route path="/faq" exact component={FAQ} />
        <Route path="/terms-and-conditions" exact component={Terms} />
        <Route path="/privacy-policy" exact component={Policy} />
        <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/checkout-Success" exact component={CheckoutSuccess} />
        <Route path="/checkout-Failed" exact component={CheckoutFailed} />
        <Route path="/reset-password/:slug" exact component={ResetPassword} />
        <Route path="/view-report" exact component={ViewReport} />
        {/* <Route component={Home} /> */}
      </Switch>
    </Layout>
  </BrowserRouter>
), document.getElementById('root'));
