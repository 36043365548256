import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import TitleTag from '../components/TitleTag';
import { DataContext } from '../components/context';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { APP_CONFIG } from '../utils/StaticData';
const Checkout = () => {
    const location = useLocation();
    const QString = queryString?.parse(location?.search);
    const [selecteedPrice, setPrice] = useState('')
    const [email, setEmail] = useState('')
    const Context = useContext(DataContext);
    const [policyCheck, setPolicyCheck] = useState(false)
    useEffect(() => {
        if (QString?.price_id && QString?.price_id !== '') {
            setPrice(QString?.price_id)
        }
    }, [QString?.price_id])
    // console.log("is_migration >>> ", location?.state?.is_migration);
    return (
        <>
            <TitleTag title={"Checkout"} />
            <section className="account-area ptb-140 bg-F6F6F6">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <form action={BASE_URL + "payment-checkout"} method="POST" onSubmit={(e) => console.log("onSubmit", e)}>
                            {localStorage.getItem('user_token') ? '' : <div className="title">
                                <h2 className="pb-2 mb-0">{APP_CONFIG.CHECKOUT_PAGE_TITLE}</h2>
                            </div>}
                            <div className="account-form my-4 bg-white">
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <div className="form-group">
                                            <label className="mb-1">{APP_CONFIG.EMAIL_LABEL} <span className="color-ff0000">*</span></label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                pattern="[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                className="form-control px-0"
                                                required={true}
                                                value={localStorage.getItem('user_token') ? Context?.user?.[0]?.email : email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter Email ID"
                                                readOnly={localStorage.getItem('user_token') ? true : false} />
                                        </div>
                                        <div className="form-group mb-0">
                                            <input
                                                id="is_migration"
                                                name="is_migration"
                                                type="hidden"
                                                className="form-control px-0"
                                                value={location?.state?.is_migration}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    {Context?.products?.map((data, i) => {
                                        return (
                                            <div key={i} class="col-xl-5 col-md-6 pb-5 pb-md-0 px-md-4 px-0 mb-4" onClick={() => {
                                                setPrice(data?.price_id)
                                            }}>
                                                < input type="hidden" id="priceId" name="priceId" value={selecteedPrice} />
                                                <div class={`pricing ${selecteedPrice == data?.price_id ? "active" : ""} py-4 h-100 pointer`}>
                                                    <div class="pricing-title-area my-0">
                                                        <h4 class="pricing-title fw-bold text-capitalize">{data?.metadata?.PRICE_TITLE ? data?.metadata?.PRICE_TITLE : data?.interval}</h4>
                                                        {data?.metadata?.PRICE_SUBTITLE &&
                                                            <h6 class={selecteedPrice == data?.price_id ? "mb-0 text-white fw-bold"
                                                                : "mb-0 fw-bold color-737373"}>
                                                                {data?.metadata?.PRICE_SUBTITLE}
                                                            </h6>}
                                                        {data?.metadata?.SAVE_PRICE && <span class="badge rounded-pill px-3 py-1 mt-2 fs-6">{data?.metadata?.SAVE_PRICE}</span>}
                                                        {/* {data?.trial_period_days && <span class="badge rounded-pill px-3 py-1 mt-2 fs-6">{data?.trial_period_days} Days free trial</span>} */}
                                                    </div>
                                                    <span class="pricing-price mb-2 mt-2">
                                                        {/* <sup></sup> */}$
                                                        <strong>{data?.unit_amount / 100}</strong>
                                                        Per {data?.interval}
                                                    </span>
                                                    {data?.trial_period_days &&
                                                        <h6 class={selecteedPrice == data?.price_id ? "mb-3 text-white fw-bold"
                                                            : "mb-3 fw-bold color-737373"}>
                                                            {data?.trial_period_days} Days free trial
                                                        </h6>}
                                                    <div class="px-4 pt-1">
                                                        <button type="button" className={selecteedPrice == data?.price_id ? "btn btn-white w-100" : "btn btn-primary w-100"} >{APP_CONFIG.SELECT_BUTTON}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center pt-2 flex-wrap">
                                <label
                                    class="customcheck "
                                > {APP_CONFIG.I_AGREE_TO_TERMS_OF_SERVICE_AND_PRIVACY_POLICY}
                                    <input checked={policyCheck} onChange={(e) => {
                                        setPolicyCheck(!policyCheck)
                                    }} type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                                {/* <h6 className="mb-0 forgot-password"><img src="/images/lock.png" alt="lock" className="me-2 lock-png" />Forgot Password?</h6> */}
                                <button disabled={!policyCheck || selecteedPrice == ''} type="submit" className="btn btn-primary btn-login mt-3 mt-md-0"><img src="/images/arrow.png" alt="arrow" className="me-2 arrow-png" />{APP_CONFIG.NEXT_BUTTON}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Checkout;