import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { Filter } from '../../utils/context';
import { APP_CONFIG } from '../../utils/StaticData';
const switchOptions = [
    { id: 1, title: 'Watching', type: 'watching' },
    { id: 2, title: 'Attachments', type: 'attachments' },
    { id: 3, title: 'Due Complete', type: 'overdue' },
]
const General = ({ Generaldata }) => {
    return (
        <div>
            <div className="border-bottom">
                {switchOptions && switchOptions.map((data, i) => {
                    return (
                        <div key={i} className="d-flex align-items-center">
                            <h6 className="fw-normal switch-label">{data.title}</h6>
                            <div className="form-check form-switch">
                                <input
                                    // disabled
                                    className="form-check-input custom-switch"
                                    type="checkbox"
                                    id={data.type}
                                    checked={Generaldata?.[data.type]}
                                />

                            </div>
                        </div>
                    )
                })}

            </div>
            <h6 className="mt-3 fw-normal">{APP_CONFIG.START_DATES_BETWEEN}</h6>
            <div className="d-flex align-items-center mb-1 pt-2">
                <div className="position-relative w-100">
                    <DatePicker
                        selectsRange={true}
                        startDate={Generaldata?.fromDate == null ? null : new Date(Generaldata?.fromDate)}
                        endDate={Generaldata?.toDate == null ? null : new Date(Generaldata?.toDate)}
                        disabled
                        dateFormat="MMM dd, yyyy"
                    />
                    <img src="/images/Calendar.svg" alt="Calendar" className="calendar-icon pe-none" />
                </div>
            </div>
            <h6 className="mt-3 fw-normal">{APP_CONFIG.DUE_DATES_BETWEEN}</h6>
            <div className="d-flex align-items-center mb-1 pt-2">
                <div className="position-relative w-100">
                    <DatePicker
                        selectsRange={true}
                        disabled
                        startDate={Generaldata?.dueFromDate == null ? null : new Date(Generaldata?.dueFromDate)}
                        endDate={Generaldata?.duetoDate == null ? null : new Date(Generaldata?.duetoDate)}
                        dateFormat="MMM dd, yyyy"
                    />
                    <img src="/images/Calendar.svg" alt="Calendar" className="calendar-icon pe-none" />
                </div>
            </div>

        </div>
    );
}

export default General;
