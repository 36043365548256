import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import TitleTag from '../components/TitleTag';
import queryString from 'query-string';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { APP_CONFIG } from '../utils/StaticData';
const CheckoutFailed = () => {
    const location = useLocation();
    const QString = queryString?.parse(location?.search);
    const [isLoading, setLoader] = useState(true);
    const checkSession = async () => {
        setLoader(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "check-session-expiry", {
                checkout_session_id: QString?.session_id
            })
            console.log("check-session-expiry", res);
            // setDetails(res?.data?.data)
            setLoader(false);
        } catch (err) {
            console.log("check-session-expiry err", err);
            setLoader(false);
        }
    }
    useEffect(() => {
        checkSession()
    }, [])
    return (
        <>
            <TitleTag title={"CheckoutFailed"} />
            <section className="faq-area pt-160 pb-0">
                <div className="container h-100 ptb-100 ">
                    {!isLoading &&
                        <div className="section-title mb-5">
                            <h2 className="pb-2 mb-0">{APP_CONFIG.PAYMENT_FAILED}</h2>
                            <div className='ptb-80'>
                                <hr className="horizontal-rule m-auto" />
                            </div>
                            <NavLink to="/checkout">
                                <button type="button" className="btn btn-primary">{APP_CONFIG.TRY_AGAIN_BUTTON}</button>
                            </NavLink>
                        </div>
                    }
                </div>
            </section>
            {/* <Footer /> */}
        </>
    );
}

export default CheckoutFailed;