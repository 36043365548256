import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { NavLink } from 'react-router-dom';
import TitleTag from '../components/TitleTag';
import * as Yup from 'yup';
import { Formik } from 'formik';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { APP_CONFIG } from '../utils/StaticData';
const Login = (props) => {
    const location = useLocation();
    const QString = queryString?.parse(location?.search);
    console.log("QString ========> ", QString);
    console.log("props => ", props);
    const [error, setError] = useState("");
    const history = useHistory()
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required("*Required")
            .matches(/^\(?([a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)$/, "Not Valid Email"),
        password: Yup.string()
            .required("*Required")
            .min(8)
            .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/, "Password must have at least 1 Lowercase, 1 Uppercase, 1 Numeric and 1 Special character.")
        ,
    });

    return (
        <>
            <TitleTag title={"Login"} />
            <section className="login-area ptb-50 vh-100 bg-F6F6F6">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-xl-5 col-lg-6 col-md-12">
                            <div className="title">
                                <h2 className="pb-2 mb-0">{APP_CONFIG.LOGIN_PAGE_TITLE}</h2>
                            </div>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',

                                }}
                                validationSchema={LoginSchema}
                                onSubmit={
                                    async (values, { resetForm }) => {
                                        try {
                                            setError('')
                                            const res = await NetworkClient.post(BASE_URL + 'login', {
                                                email: values.email,
                                                password: values.password,
                                                // remember: check ? "on" : "off"
                                            })
                                            console.log("res", res.data);
                                            if (res.data.data && res.data.success) {
                                                localStorage.setItem('user_token', res.data.data?.token)
                                                resetForm('')
                                                if (QString?.redirectTo) {
                                                    history.push(QString?.redirectTo)
                                                }
                                                else {
                                                    history.push('/subscriptions')
                                                }
                                            }

                                        } catch (err) {
                                            console.log("err", err?.response);
                                            setError(err?.response?.data?.data?.error)
                                        }
                                    }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <>
                                        <div className="login-form my-4">
                                            <div className="row">
                                                <div className="col-12 mb-5">
                                                    <div className="form-group mb-0">
                                                        <label>{APP_CONFIG.EMAIL_LABEL}</label>
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoFocus={true}
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            className="form-control px-0"
                                                            required={true}
                                                            placeholder="Email" />
                                                        <span className='text-danger'>
                                                            {errors.email && touched.email && errors.email}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <label>{APP_CONFIG.PASSWORD_LABEL}</label>
                                                    <div className="form-group mb-0">
                                                        <input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            value={values?.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            className="form-control px-0"
                                                            required={true}
                                                            placeholder="Password" />
                                                        <span className='text-danger'>
                                                            {errors.password && touched.password && errors.password}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-4">
                                            <span className='text-danger'>
                                                {error}
                                            </span>
                                        </div>
                                        {/* <div className="col-12">
                                            <label
                                                class="customcheck">
                                                Keep me logged in
                                                <input id="remember"
                                                    checked={check}
                                                    onClick={() => {
                                                        setCheck(!check)
                                                    }}
                                                    name="remember" type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div> */}

                                        <div className="d-flex justify-content-between align-items-center pt-2">
                                            <button
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                                className="btn btn-primary btn-login">
                                                {isSubmitting ? <div className="spinner-border spinner-border-sm text-white" /> :
                                                    <>
                                                        <img src="/images/login.png" alt="login" className="me-2 login-png" />
                                                        {APP_CONFIG.LOGIN_PAGE_TITLE}
                                                    </>
                                                }
                                            </button>
                                            <NavLink to="/forgot-password">
                                                <h6 className="mb-0 forgot-password"><img src="/images/lock.png" alt="lock" className="me-2 lock-png" />{APP_CONFIG.FORGOT_PASSWORD}</h6>
                                            </NavLink>
                                        </div>

                                    </>
                                )}
                            </Formik>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;