import React from 'react';
import { Modal } from 'react-responsive-modal';

const AlertModal = ({ message, isOpen, closeModal, Title, isError, onCancle, onOk, noCancle, noOK }) => {

    return (
        <Modal showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false} open={isOpen} onClose={closeModal} center classNames={{ modal: "py-4" }}>
            <h4 className={`modal-heading fw-bold ${isError ? "text-danger" : ""} `}>{Title}</h4>
            <h6 className="mt-3 mb-4">
                {message}
            </h6>
            <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-primary w-50 py-2" onClick={closeModal}>OK</button>
            </div>
        </Modal >
    )
}

export default AlertModal;