import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { getLabelColor } from '../components/colorMapper';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
const DateActivity = ({ data, keyObj, uid }) => {
    const [spinner, setSpinner] = useState(false);
    const [lastActivityUser, setLastActivityUser] = useState('');
    const onHover = async (data) => {
        // console.log("data =======> ", data);
        setLastActivityUser('')
        setSpinner(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "get-card-last-activity", {
                unique_id: uid,
                card_id: data?.id,
            })
            if (res?.data?.code == 200) {
                // console.log("res?.data ", res?.data);
                setLastActivityUser(res?.data?.data)
            }
            setSpinner(false);
        } catch (err) {
            console.log("onHover", err?.response);
            setSpinner(false);
        }
    };
    return (
        <>
            <div class="date-last-activity-tooltip" onMouseEnter={() => onHover(data)}>
                <div class="tooltiptext">{spinner ? <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span> : lastActivityUser}</div>
                <i class="icofont-user-alt-3 me-1"></i>
            </div>
            <span>{data[keyObj?.value]?.key ? moment(data[keyObj?.value]?.key).format('lll') : data[keyObj?.value] ? moment(data[keyObj?.value]).format('lll') : ''}</span>
        </>
    )
}
const ShareReportDateActivity = ({ data, keyObj, uid }) => {
    const [spinner, setSpinner] = useState(false);
    const [lastActivityUser, setLastActivityUser] = useState('');
    const onHover = async (data) => {
        setLastActivityUser('')
        setSpinner(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "get-last-activity-for-public-report", {
                shareable_unique_id: uid,
                card_id: data?.id,
            })
            if (res?.data?.code == 200) {
                // console.log("res?.data ", res?.data);
                setLastActivityUser(res?.data?.data)
            }
            setSpinner(false);
        } catch (err) {
            console.log("onHover", err?.response);
            setSpinner(false);
        }
    };
    return (
        <>
            <div class="date-last-activity-tooltip" onMouseEnter={() => onHover(data)}>
                <div class="tooltiptext">{spinner ? <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span> : lastActivityUser}</div>
                <i class="icofont-user-alt-3 me-1"></i>
            </div>
            <span>{data[keyObj?.value]?.key ? moment(data[keyObj?.value]?.key).format('lll') : data[keyObj?.value] ? moment(data[keyObj?.value]).format('lll') : ''}</span>
        </>
    )
}
function descendingComparator(a, b, orderBy) {
    var ValueB = b[orderBy]?.key ? b[orderBy]?.key : b[orderBy] ? b[orderBy] : ''
    var ValueA = a[orderBy]?.key ? a[orderBy]?.key : a[orderBy] ? a[orderBy] : ''
    if ((ValueB < ValueA) || (ValueB < ValueA)) {
        return -1;
    }
    if ((ValueB > ValueA) || (ValueB > ValueA)) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    Sr.No.
                </TableCell>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={"headCells-" + headCell.id}
                            align={'center'}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.value ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.value}
                                direction={orderBy === headCell.value ? order : 'asc'}
                                onClick={createSortHandler(headCell.value)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};
const getlableValue = (data, keyObj) => {
    const type = data?.[keyObj?.value];
    if (type?.key) {
        if (Array.isArray(type?.key)) {
            return type?.key?.map((data, i) => {
                return (<div key={'LabelColor' + i}>
                    <span style={{ backgroundColor: getLabelColor(data?.split('||')?.[1]), display: 'block' }} class="badge rounded-pill px-2 mb-2 fs-6 user-report-label">{data?.split('||')?.[0]}</span>
                </div>)
            })
        } else {
            return <span style={{ backgroundColor: getLabelColor(type?.key?.split('||')?.[1]), display: 'block' }} class="badge rounded-pill px-2 mb-2 fs-6 user-report-label">{type?.key?.split('||')?.[0]}</span>
        }
    } else {
        if (Array.isArray(type)) {
            return type?.map((data, i) => {
                return (<div key={'LabelColor' + i}>
                    <span style={{ backgroundColor: getLabelColor(data?.split('||')?.[1]), display: 'block' }} class="badge rounded-pill px-2 mb-2 fs-6 user-report-label">{data?.split('||')?.[0]}</span>
                </div>)
            })
        } else {
            return <span style={{ backgroundColor: getLabelColor(type?.split('||')?.[1]), display: 'block' }} class="badge rounded-pill px-2 mb-2 fs-6 user-report-label">{type?.split('||')?.[0]}</span>
        }
    }
}
const getHighliters = (data, keyObj) => {
    const type = data?.[keyObj?.value];
    if (Array.isArray(type)) {
        return (
            <div className='d-flex'>
                {type?.map((data, i) => {
                    return (
                        <span key={'Highliters -' + i}>
                            {data?.backgroundColor &&
                                <span style={{ display: 'block', backgroundColor: data?.backgroundColor, borderWidth: 1, width: '5px', height: '30px' }} class="user-report-label"></span>}
                            {
                                data?.selectedIcon ?
                                    Array.isArray(data?.selectedIcon) ?
                                        data?.selectedIcon?.map((e, iconIndex) => (
                                            <i key={'Highliters -' + i + "-icon-" + iconIndex} className={e} />
                                        ))
                                        :
                                        <i className={data?.selectedIcon}></i>
                                    : ""}
                        </span>
                    )
                })}
            </div>
        )
    }
}
const TableViewData = ({ handleRequestSort, handleChangePage, tableHeader, order, orderBy, handleChangeRowsPerPage, tableData, rowsPerPage, page, viewOnly, uid }) => {

    return (
        <div className="container">
            {/* <EnhancedTableToolbar reportsIds={reportsIds} uid={uid} /> */}
            <TableContainer className="user-reports-table-height">
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    className="user-reports-table"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={tableHeader}
                    />
                    <TableBody>
                        {stableSort(tableData, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((data, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        tabIndex={-1}
                                        key={labelId}
                                    >
                                        <TableCell>
                                            {(page * rowsPerPage) + index + 1}
                                        </TableCell>
                                        {tableHeader && tableHeader?.map((keyObj, arrayIndex) => {
                                            let key = '';
                                            switch (keyObj?.value) {
                                                case "strips":
                                                    key = getHighliters(data, keyObj)
                                                    break;
                                                case "idLabels":
                                                    key = getlableValue(data, keyObj)
                                                    break;
                                                case "due":
                                                    key = data[keyObj?.value]?.key ? moment(data[keyObj?.value]?.key).format('lll') : data[keyObj?.value] ? moment(data[keyObj?.value]).format('lll') : ''
                                                    break;
                                                case "startDate":
                                                    key = data[keyObj?.value]?.key ? moment(data[keyObj?.value]?.key).format('lll') : data[keyObj?.value] ? moment(data[keyObj?.value]).format('lll') : ''
                                                    break;
                                                case "endDate":
                                                    key = data[keyObj?.value]?.key ? moment(data[keyObj?.value]?.key).format('lll') : data[keyObj?.value] ? moment(data[keyObj?.value]).format('lll') : ''
                                                    break;
                                                case "dateLastActivity":
                                                    key = viewOnly ? <ShareReportDateActivity uid={uid} data={data} keyObj={keyObj} /> : <DateActivity uid={uid} data={data} keyObj={keyObj} />
                                                    break;
                                                case "subscribed":
                                                    key = data[keyObj?.value] ? "Yes" : 'No'
                                                    break;
                                                case "dueComplete":
                                                    key = data[keyObj?.value] ? "Yes" : 'No'
                                                    break;
                                                case "shortUrl":
                                                    key = <a className="text-primary" href={data[keyObj?.value]} target='_blank' rel="noreferrer" >{data[keyObj?.value]}</a>
                                                    break;
                                                default:
                                                    key = data[keyObj?.value]?.key ? data[keyObj?.value]?.key : data[keyObj?.value]
                                                    break;
                                            }
                                            return (
                                                <TableCell
                                                    key={labelId + '-cell-' + arrayIndex}
                                                    className={keyObj?.value == 'desc' ? "w-500" : keyObj?.value == "name" ? "w-300" : keyObj?.value && key == "strips" ? "w-80" : "w-150"
                                                    }
                                                    style={data[keyObj?.value]?.key ? data[keyObj?.value]?.style : {}} align="center" >
                                                    < div className={`${keyObj?.value == 'desc' ? "description" : ""}`}>
                                                        {keyObj?.value == 'desc' ?
                                                            <>
                                                                <span data-tip={key} >{key}</span>
                                                                <ReactTooltip place="top" className="w-75" />
                                                            </>
                                                            : key}
                                                    </div>
                                                    {
                                                        data[keyObj?.value]?.style?.selectedIcon ?
                                                            Array.isArray(data[keyObj?.value]?.style?.selectedIcon) ?
                                                                data[keyObj?.value]?.style?.selectedIcon?.map((e) => (
                                                                    <div>
                                                                        <i className={e} />
                                                                    </div>
                                                                ))
                                                                :
                                                                <i className={data[keyObj?.value]?.style?.selectedIcon}></i>
                                                            : ""
                                                    }
                                                </TableCell>
                                            )
                                        })
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer >
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={tableData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div >
    )
}
export default TableViewData;