import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { APP_CONFIG } from '../../utils/StaticData';

export class Navbar extends Component {
    _isMounted = false;

    state = {
        drawer: false,
        searchForm: false,
        collapsed: true,
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    onServicesClick = () => {
        this.props.onServicesClick()
        this.toggleNavbar()
    }

    onPricingClick = () => {
        this.props.onPricingClick()
        this.toggleNavbar()
    }

    onContactClick = () => {
        this.props.onContactClick()
        this.toggleNavbar()
    }

    handleSearchForm = () => {
        this.setState(prevState => {
            return {
                searchForm: !prevState.searchForm
            };
        });
    }

    handleDrawer = () => {
        this.setState(prevState => {
            return {
                drawer: !prevState.drawer
            };
        });
    }

    componentDidMount() {
        this._isMounted = true;
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        console.log("this.props", this.props);

        // let { pathname } = this.props.router;
        let { products, userData } = this.props;
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        let layOutCls = '';
        return (
            <header id="header" ref={this.props.reference}>
                <div id="navbar" className={`artflex-nav ${layOutCls} border-bottom-0`}>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink to="/" className="navbar-brand">
                                <img src="/images/logo.svg" alt="logo" style={{ width: '250px' }} className="mw-100" />
                                {/* <span className="fw-bold text-white">Data Highlighter</span> */}
                            </NavLink>

                            <div>
                                <NavLink to="/checkout">
                                    <button type="button" className="btn btn-white navbar-btn d-block d-lg-none ms-auto">{APP_CONFIG.BUY_NOW_BUTTON}</button>
                                </NavLink>

                                <button
                                    onClick={this.toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    {/* <span className="navbar-toggler-icon"></span> */}
                                    <img src="/images/bx_bx-menu-alt-right.png" alt="logo" />

                                </button>
                            </div>
                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto align-items-lg-center">
                                    {/* <li className="nav-item">
                                        <NavLink to="/" exact activeClassName="active" className="nav-link">
                                            Home 
                                        </NavLink>
                                    </li> */}

                                    <li className="nav-item">
                                        {this.props.location.pathname === "/" ?
                                            <a onClick={this.onServicesClick} className="pointer">
                                                {APP_CONFIG.NAVBAR_FEATURES_LINK}
                                            </a>
                                            :
                                            <a href="/#Features">
                                                {APP_CONFIG.NAVBAR_FEATURES_LINK}
                                            </a>
                                        }
                                    </li>

                                    <li className="nav-item">
                                        {this.props.location.pathname === "/" ?
                                            <a onClick={this.onPricingClick} className="pointer">
                                                {APP_CONFIG.NAVBAR_PRICING_LINK}
                                            </a>
                                            :
                                            <a href="/#Pricing">
                                                {APP_CONFIG.NAVBAR_PRICING_LINK}
                                            </a>
                                        }
                                    </li>

                                    <li className="nav-item">
                                        <a href="/contact-us">
                                            {APP_CONFIG.NAVBAR_CONTACT_LINK}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/faq" onClick={this.toggleNavbar}>
                                            {APP_CONFIG.NAVBAR_FAQ_LINK}
                                        </NavLink>
                                    </li>
                                    {localStorage.getItem('user_token') ?
                                        <li className="py-2">
                                            <NavLink to="/subscriptions" onClick={this.toggleNavbar}>
                                                <button type="button" class="btn btn-secondary btn-manage-licenses ms-lg-2">{APP_CONFIG.NAVBAR_SUBSCRIPTIONS_BUTTON} </button>
                                            </NavLink>
                                        </li>
                                        :
                                        <li className="py-2">
                                            <NavLink to="/login" onClick={this.toggleNavbar}>
                                                <button type="button" class="btn btn-secondary btn-manage-licenses ms-lg-2">{APP_CONFIG.NAVBAR_MANAGE_LICENSES_BUTTON}  <img src="/images/key.svg" alt="key" className="key-svg ms-2" /></button>
                                            </NavLink>
                                        </li>
                                    }
                                    {localStorage.getItem('user_token') ?
                                        <li className="py-3">
                                            <NavLink to="/user-report" onClick={this.toggleNavbar}>
                                                <button type="button" className="btn btn-white navbar-btn-white-ml">{APP_CONFIG.NAVBAR_REPORTS_BUTTON}</button>
                                            </NavLink>
                                        </li>
                                        :
                                        <li className="py-3">
                                            <NavLink to="/checkout" onClick={this.toggleNavbar}>
                                                <button type="button" className="btn btn-white navbar-btn-white-ml">{APP_CONFIG.BUY_NOW_BUTTON}</button>
                                            </NavLink>
                                        </li>
                                    }
                                </ul>

                                {/* <button type="button" class="btn btn-secondary btn-manage-licenses">Manage Licenses <img src="/images/key.svg" alt="key" className="key-svg ms-2" /></button> */}
                                {/* {localStorage.getItem('user_token') ?
                                <NavLink to="/user-report" className="d-none d-lg-block ">
                                    <button type="button" className="btn btn-white navbar-btn-white-ml">Reports</button>
                                </NavLink>
                                :
                                <NavLink to="/checkout" className="d-none d-lg-block ">
                                    <button type="button" className="btn btn-white navbar-btn-white-ml">Buy Now</button>
                                </NavLink>} */}
                                {localStorage.getItem('user_token') ?
                                    <div className="others-option ms-0">
                                        <ul className="navbar-nav mx-auto pe-0">
                                            <li className="nav-item">
                                                <a className="nav-link mx-0 ms-lg-4 me-lg-3 pointer border-bottom-0 d-flex justify-content-center justify-content-md-start align-items-center">
                                                    <img src={"/images/user.svg"} alt="User" />
                                                    <span className="ps-2 username">{userData?.[0]?.email}</span> <i className="icofont-simple-down ms-1"></i>
                                                </a>

                                                <ul className="dropdown_menu py-1">
                                                    {/* <div>
                                                <li className="nav-item">
                                                    <NavLink to="/checkout" className="d-none d-lg-block ">
                                                        HEADER_PROFILE_PAGE
                                                    </NavLink>
                                                </li>
                                            </div> */}
                                                    {/* <div>
                                                <li className="nav-item">
                                                    <NavLink to="/checkout" className="d-none d-lg-block ">
                                                        ACCOUNT_CHANGE_PASSWORD
                                                    </NavLink>
                                                </li>
                                            </div> */}
                                                    <li className="nav-item">
                                                        <a href='/' style={{ cursor: 'pointer' }} onClick={async () => {
                                                            // await this.props.storeUserActivity('user_Logout');
                                                            // await Cookies.set('token', '')
                                                            // await this.props.setUserToken(null);

                                                            localStorage.removeItem('user_token')
                                                            window.location?.reload();
                                                        }} className="nav-link">
                                                            {APP_CONFIG.NAVBAR_LOGOUT_LINK}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    : null} </div></nav>
                    </div>
                    {/* 
                    <SearchForm onClick={this.handleSearchForm} active={this.state.searchForm ? 'active' : ''} />

                    <SideDrawer onClick={this.handleDrawer} show={this.state.drawer ? 'show' : ''} /> */}
                </div>
            </header>
        );
    }
}

export default withRouter(Navbar);
