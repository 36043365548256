import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { BASE_URL } from '../../../utils/globle';
import NetworkClient from '../../../utils/NetworkClient';
import { NavLink } from 'react-router-dom';
import TitleTag from '../../../components/TitleTag';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AlertModal from "../../../components/AlertModal"
import { APP_CONFIG } from '../../../utils/StaticData';
const ResetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [new_password, setNewPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [model, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [redirect, setRedirect] = useState('');
    const history = useHistory()
    const userToken = props.match.params.slug;
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required("*Required")
            .matches(/^\(?([a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)$/, "Not Valid Email"),
        new_password: Yup.string()
            .required("*Required")
            .min(8, "Password must be at least 8 characters")
            .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/, "Password must have at least 1 Lowercase, 1 Uppercase, 1 Numeric and 1 Special character."),
        confirm_password: Yup.string()
            .required('Required')
            .oneOf([Yup.ref("new_password"), null], 'Passwords must match')

    });
    return (
        <>
            <TitleTag title={"Reset Password"} />
            <section className="reset-password-area ptb-100 vh-100 bg-F6F6F6">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-xl-5 col-lg-6 col-md-12">
                            <div className="title">
                                <h2 className="pb-2 mb-0">{APP_CONFIG.RESET_YOUR_PASSWORD_PAGE_TITLE}</h2>
                            </div>
                            <Formik
                                initialValues={{
                                    email: '',
                                    new_password: '',
                                    confirm_password: ""
                                }}
                                validationSchema={LoginSchema}
                                onSubmit={
                                    async (values, { resetForm }) => {
                                        try {
                                            const res = await NetworkClient.post(BASE_URL + "update-Password", {
                                                email: values?.email,
                                                new_password: values?.new_password,
                                                confirm_password: values?.confirm_password,
                                                token: userToken,
                                            })
                                            console.log("res", res.data);
                                            if (res.data.data && res.data.success) {
                                                // history.push('/login')
                                                console.log("res", res.data.data);
                                                resetForm('')
                                                // alert(res.data.message)
                                                setTitle('Success');
                                                setMessage(res?.data?.message)
                                                setModal(true)
                                                setRedirect('/login');
                                            }

                                        } catch (err) {
                                            console.log("err", err?.response);
                                            // setError(err?.response?.data?.data?.error)
                                            setTitle('Error !!');
                                            setMessage(err?.response?.data?.message)
                                            setModal(true)
                                            setRedirect('');
                                        }
                                    }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <>
                                        <div className="reset-password-form my-4 bg-white">
                                            <div className="row">
                                                <div className="col-12 mb-5">
                                                    <div className="form-group mb-0">
                                                        <label>{APP_CONFIG.EMAIL_LABEL}</label>
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoFocus
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            className="form-control px-0"
                                                            placeholder="Email" />
                                                        <span className='text-danger'>
                                                            {errors.email && touched.email && errors.email}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 mb-5">
                                                    <div className="form-group mb-0">
                                                        <label>{APP_CONFIG.NEW_PASSWORD_LABEL}</label>
                                                        <input
                                                            id="new_password"
                                                            name="new_password"
                                                            type="password"
                                                            value={values?.new_password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            className="form-control px-0"
                                                            required={true}
                                                            placeholder="New Password" />
                                                        <span className='text-danger'>
                                                            {errors.new_password && touched.new_password && errors.new_password}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-12 ">
                                                    <div className="form-group mb-0">
                                                        <label>{APP_CONFIG.CONFIRM_PASSWORD_LABEL}</label>
                                                        <input
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            type="password"
                                                            value={values?.confirm_password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            className="form-control px-0"
                                                            required={true}
                                                            placeholder="Confirm Password" />
                                                        <span className='text-danger'>
                                                            {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center pt-2">
                                            <button
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                                className="btn btn-primary btn-login">
                                                {isSubmitting ? <div className="spinner-border spinner-border-sm text-white" /> :
                                                    <>
                                                        <img src="/images/Send.png" alt="Send" className="me-2 login-png" />
                                                        {APP_CONFIG.SUBMIT_BUTTON}
                                                    </>}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
                <AlertModal
                    isError={title == "Error !!"}
                    isOpen={model}
                    Title={title}
                    message={message}
                    closeModal={() => {
                        setModal(false);
                        setMessage('');
                        setTitle('')
                        if (redirect !== '') {
                            setRedirect('');
                            history.push('/login');
                        }
                    }} />
            </section>
        </>
    );
}

export default withRouter(ResetPassword);