import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import TitleTag from '../components/TitleTag';
import { BASE_URL, CAPTCHA_KEY } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import ReCAPTCHA from "react-google-recaptcha";
import AlertModal from '../components/AlertModal';
const ContactUS = () => {
    const [model, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const reCaptchaRef = useRef()
    const Schema = Yup.object().shape({
        email: Yup.string()
            .required("*Required")
            .matches(/^\(?([a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)$/, "Not Valid Email"),
        name: Yup.string()
            .required("*Required"),
        message: Yup.string().required("*Required"),
        subject: Yup.string().required("*Required").notOneOf([0, -1], "*Required")
    });
    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptcha(value)
    }
    return (
        <>
            <TitleTag title={"Contact-us"} />

            <section className="contact-area ptb-120">
                <div className="container">
                    <div className="section-title mb-4">
                        <h2>Contact Us</h2>
                        <span style={{ color: '#797777' }} className='pt-3'>For General inquiries, Check our <a style={{
                            textDecoration: 'underline'
                        }} href="/faq">FAQs</a>  Section</span>
                    </div>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12">

                            <Formik
                                initialValues={{
                                    email: '',
                                    name: '',
                                    message: '',
                                    subject: '',
                                }}
                                validationSchema={Schema}
                                onSubmit={
                                    async (values, { resetForm }) => {
                                        console.log("values", values);
                                        try {
                                            const res = await NetworkClient.post(BASE_URL + 'contact-us', { ...values, googleResponse: captcha })
                                            console.log("contact-us res", res.data);
                                            if (res.data.data && res.data.status) {
                                                setTitle('Success');
                                                setMessage(res.data?.data)
                                                setModal(true)
                                                resetForm('')
                                                reCaptchaRef?.current.reset()
                                                setCaptcha(null)
                                            } else if (res.data.code == 400 && res.data?.data?.googleResponse) {
                                                setMessage(res.data?.data?.googleResponse[0])
                                                setTitle('Error');
                                                setModal(true)
                                            }

                                        } catch (err) {
                                            console.log("err", err?.response);
                                            if (err?.response?.data?.data?.googleResponse) {
                                                setMessage(err?.response?.data?.data?.googleResponse[0])
                                            }
                                        }
                                    }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <div className='contactus'>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-6">
                                                <div className="form-group">
                                                    <label>{"*Select Subject"}</label>
                                                    <select
                                                        defaultValue={-1}
                                                        id='subject'
                                                        name='subject'
                                                        value={values?.subject == '' ? -1 : values?.subject}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="form-select">
                                                        <option value={-1} disabled selected>"Select any..</option>
                                                        <option value="Payment">Payment</option>
                                                        <option value="Support">Support</option>
                                                        <option value="About_Product">About Product</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className='text-danger'>
                                                        {errors.subject && touched.subject && errors.subject}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{"*Full Name"}</label>
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        value={values?.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyPress={(e) => {
                                                            if (e.code === 'Enter') {
                                                                handleSubmit()
                                                            }
                                                        }}
                                                        className="form-control"
                                                        required={true}
                                                        placeholder="Full Name" />
                                                    <span className='text-danger'>
                                                        {errors.name && touched.name && errors.name}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{"*Email"}</label>
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        value={values?.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyPress={(e) => {
                                                            if (e.code === 'Enter') {
                                                                handleSubmit()
                                                            }
                                                        }}
                                                        className="form-control"
                                                        required={true}
                                                        placeholder="Email" />
                                                    <span className='text-danger'>
                                                        {errors.email && touched.email && errors.email}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{"*Message"}</label>
                                                    <textarea
                                                        id="message"
                                                        cols="30" rows="5"
                                                        name="message"
                                                        value={values?.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onKeyPress={(e) => {
                                                            if (e.code === 'Enter') {
                                                                handleSubmit()
                                                            }
                                                        }}
                                                        className="form-control"
                                                        required={true}
                                                        placeholder="Message" />
                                                    <span className='text-danger'>
                                                        {errors.message && touched.message && errors.message}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 d-flex justify-content-between align-items-center">
                                                <ReCAPTCHA
                                                    style={{ display: "inline-block" }}
                                                    theme="light"
                                                    type='image'
                                                    id="googleResponse"
                                                    name="googleResponse"
                                                    ref={reCaptchaRef}
                                                    sitekey={CAPTCHA_KEY}
                                                    onChange={onChange}
                                                // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                />
                                                <div className=' justify-content-center '>
                                                    <button
                                                        disabled={isSubmitting || captcha == null}
                                                        onClick={() => {
                                                            handleSubmit()
                                                        }}
                                                        className="btn btn-primary">
                                                        {isSubmitting ? <div className="spinner-border spinner-border-sm text-white" /> :
                                                            <>Send
                                                            </>
                                                        }
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
            <AlertModal
                isOpen={model}
                Title={title}
                message={message}
                closeModal={() => {
                    setModal(false);
                    setMessage('');
                    setTitle('')
                }} />
        </>
    );

}

export default ContactUS;
