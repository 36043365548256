import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/StaticData';

const Banner = () => {

    return (
        <div className="main-banner digital-agency-banner">

            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container text-center">
                        <div className="row h-100 align-items-center justify-content-center pb-4">
                            <div className="col-lg-12 col-md-12">
                                <div className="hero-content ">
                                    <h1 className="text-white mb-4">{APP_CONFIG.BANNER_TITLE_1}</h1>
                                    <h4 className="text-white mb-4 px-5 lh-base">{APP_CONFIG.BANNER_TITLE_2}</h4>
                                    <h4 className="text-white mb-4 px-5 lh-base">{APP_CONFIG.BANNER_TITLE_3}</h4>
                                    <a href='https://trello.com/power-ups/616e5840978fc41ab7ad87a0' target='_blank' rel="noreferrer">
                                        <button type="button" className="btn btn-light mt-3 mb-4"><i className="icofont-trello me-2"></i>{APP_CONFIG.ADD_TO_TRELLO_BUTTON}</button>
                                    </a>
                                    <img src="/images/screencontent.png" alt="logo" className="pt-3 ptb-80" />
                                </div>
                            </div>
                        </div>
                        <hr className="horizontal-rule m-auto" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
