import React, { useEffect, useMemo, useState } from 'react';
import GoTop from '../shared/GoTop';
import Footer from './Footer';
import Navbar from './Navbar';
import Cookie from '../CookieConsent/CookieConsent';
import { useLocation } from 'react-router-dom';
import { BASE_URL } from '../../utils/globle';
import NetworkClient from '../../utils/NetworkClient';
import { DataContext } from '../context';
import ProcessErrorAndRespond from '../ProcessErrorAndRespond';

export default function Layout({ children }) {
    const location = useLocation();
    const [userData, setUserData] = useState({})
    const [productData, setProduct] = useState([]);
    const [err, setErr] = useState({});
    const Context = () => {
        return {
            user: userData,
            products: productData
        };
    };
    const getUserDetails = async () => {
        try {
            const res = await NetworkClient.get(BASE_URL + "get-user-details")
            setUserData(res?.data?.data)
        } catch (err) {
            console.log("get-user-details err", err?.response);
            if (err?.response?.status == 401) {
                localStorage.removeItem('user_token')
                window.location?.reload();
            }
        }
    }
    const getPreducts = async () => {
        try {
            const res = await NetworkClient.get(BASE_URL + "get-product-pricing")
            // console.log("res", res?.data?.data);
            setProduct(res?.data?.data)
        } catch (err) {
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const token = localStorage.getItem('user_token')
    const dataContext = useMemo(() => Context(), [productData, userData]);
    useEffect(() => {
        if (token !== null && token !== '') {
            getUserDetails()
        }
        getPreducts()
    }, [token])
    return (
        <DataContext.Provider value={dataContext}>
            <ProcessErrorAndRespond Error={err} />
            {location.pathname === "/" ? "" : <Navbar userData={userData} />}
            {children}
            <Cookie />
            <GoTop scrollStepInPx="50" delayInMs="16.66" />
        </ DataContext.Provider>
    );
}
