import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import UserRefModal from '../components/Modal';
import ProcessErrorAndRespond from '../components/ProcessErrorAndRespond';
import Loader from '../components/shared/Loader';
import { ListingLoader } from '../components/SkeletonLoader';
import TableViewData from '../components/Table';
import TitleTag from '../components/TitleTag';
import { TablesKeys } from '../utils/StaticData';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';

const ViewReport = () => {
    const location = useLocation();
    const QString = queryString?.parse(location?.search);
    const [err, setErr] = useState({});
    const [tableData, setTableData] = useState([])
    const [isLoadingReport, setReportLoader] = useState(false);
    const [isModalVisible, setVisible] = useState(false);
    const [tableHeader, setTableHeader] = useState([])
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('dateLastActivity');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const getReport = async (id) => {
        setReportLoader(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "public-report", {
                shareable_unique_id: id
            })
            if (res?.data?.code == 401) {
                setErr(res?.data)
                setTimeout(() => {
                    setErr({})
                }, 1000)
            } else if (res?.data?.code == 400) {
                setErr(res?.data)
                setTimeout(() => {
                    setErr({})
                }, 1000)
            }
            else if (res?.data?.code == 200) {
                setTableData(res?.data?.data?.trello_preference)
                if (res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields?.length > 0 && res?.data?.data?.trello_selected_preference?.userPref?.highiler?.length == 0) {
                    setTableHeader([...res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields])
                } else if (res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields?.length > 0) {
                    setTableHeader([{ id: 101, value: 'strips', label: '' }, ...res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields])
                } else {
                    setTableHeader(TablesKeys)
                }
            }
            setReportLoader(false);
        } catch (err) {
            console.log("public-report err", err?.response);
            setReportLoader(false);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        if (QString?.unique_id && QString?.unique_id !== '') {
            getReport(QString?.unique_id)
        }
    }, [QString?.unique_id])
    return (
        <>
            <TitleTag title={"User Report"} />
            <Loader loader={isLoadingReport} />
            <ProcessErrorAndRespond Error={err} />
            <section className="licence-manager min-vh-100 bg-F6F6F6">
                {isLoadingReport ? <div className='container pt-4'> <ListingLoader /> </div> :
                    tableData?.length > 0 ?
                        <TableViewData
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            tableData={tableData}
                            tableHeader={tableHeader}
                            viewOnly={true}
                            uid={QString?.unique_id} />
                        :
                        <div className="container">
                            <div className="section-title pt-160">
                                <h4 className="color-737373">No Data found</h4>
                            </div>
                        </div>
                }
            </section>
            <UserRefModal open={isModalVisible} onClose={() => setVisible(false)} />

        </>
    );
}

export default ViewReport;
