import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from 'yup';
import { BASE_URL, CAPTCHA_KEY } from '../../utils/globle';
import NetworkClient from '../../utils/NetworkClient';
import { APP_CONFIG } from '../../utils/StaticData';
import AlertModal from '../AlertModal';
const Contact = (props) => {
    const [model, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const reCaptchaRef = useRef()
    const Schema = Yup.object().shape({
        email: Yup.string()
            .matches(/^\(?([a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)$/, "Not Valid Email"),
    });
    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptcha(value)
    }
    return (
        <section id='contact' className="contact-cta-area ptb-140" ref={props?.reference}>
            <div className="container">

                <div className="row h-100 justify-content-center align-items-center">
                    <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6">
                        <Formik
                            initialValues={{ email: '' }}
                            validationSchema={Schema}
                            onSubmit={
                                async (values, { resetForm }) => {
                                    console.log("values", values)
                                    if (values.email !== '') {
                                        console.log("onSubmit");
                                        try {
                                            const res = await NetworkClient.post(BASE_URL + "store-news-letter", {
                                                email: values?.email,
                                                googleResponse: captcha
                                            })
                                            console.log("store-news-letter", res?.data);
                                            resetForm("")
                                            reCaptchaRef?.current.reset()
                                            setCaptcha(null)
                                            // alert(res?.data?.message)
                                            setTitle('Success');
                                            setMessage(res?.data?.message)
                                            setModal(true)
                                        } catch (err) {

                                            if (err?.response?.data?.success == false) {
                                                // alert(err?.response?.data?.data?.email[0])
                                                console.log("Error", err?.response?.data?.data);
                                                setTitle('Error');
                                                if (err?.response?.data?.data?.googleResponse) {
                                                    setMessage(err?.response?.data?.data?.googleResponse[0])
                                                } else {
                                                    setMessage(err?.response?.data?.data?.email[0])
                                                }
                                                setModal(true)

                                            }
                                        }
                                    }
                                }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                                /* and other goodies */
                            }) => (
                                <div className="contact-cta-content newsletter">
                                    <div className="section-title">
                                        <h4 className="color-E77C40">{APP_CONFIG.HOME_CONTACT_SECTION_TITLE}</h4>
                                        <h2>{APP_CONFIG.HOME_CONTACT_SECTION_NEWSLETTER_TITLE}</h2>
                                    </div>
                                    <div className="subscribe-newsletter mb-2">
                                        <h4 className="color-252B42">{APP_CONFIG.HOME_CONTACT_SECTION_SUBTITLE_1} </h4>
                                        <p>{APP_CONFIG.HOME_CONTACT_SECTION_SUBTITLE_2} </p>
                                        <input
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyPress={(e) => {
                                                if (e.code === 'Enter') {
                                                    handleSubmit()
                                                }
                                            }}
                                            type="text"
                                            className="form-control custom-placeholder mt-4"
                                            required={true}
                                            placeholder="Your Email" />
                                        <span className='d-flex text-danger'>
                                            {errors.email && touched.email && errors.email}
                                        </span>
                                    </div>
                                    <div className="subscribe-newsletter mb-2">
                                        <ReCAPTCHA
                                            style={{ display: "inline-block" }}
                                            theme="light"
                                            type='image'
                                            id="googleResponse"
                                            name="googleResponse"
                                            ref={reCaptchaRef}
                                            sitekey={CAPTCHA_KEY}
                                            onChange={onChange}
                                        // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                        />
                                        <span className='d-flex text-danger'>
                                            {errors.googleResponse && touched.googleResponse && errors.googleResponse}
                                        </span>
                                    </ div>
                                    <button
                                        disabled={isSubmitting || values.email == '' || captcha == null}
                                        onClick={() => {
                                            handleSubmit()
                                        }} className="btn btn-primary w-100 mt-1">
                                        {isSubmitting ?
                                            <div className="spinner-border spinner-border-sm text-white" /> :
                                            "Subscribe"}
                                    </button>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <AlertModal
                isOpen={model}
                Title={title}
                message={message}
                closeModal={() => {
                    setModal(false);
                    setMessage('');
                    setTitle('')
                }} />
        </section>
    );
}

export default Contact;
