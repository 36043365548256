import React from 'react';
import {
    sortableHandle
} from 'react-sortable-hoc';
import { APP_CONFIG } from '../../utils/StaticData';
//Drag handler
const DragHandle = sortableHandle(() => (
    <span className="drag-handler">
        {/* <FontAwesomeIcon icon={faBars} /> */}
        <img src="/images/Drag-drop.svg" alt="Drag-drop" />
    </span>
));
const Fields = ({ data }) => {
    console.log("Fields data", data);
    return (
        <div>
            <h6 className="mb-4 fw-normal">{APP_CONFIG.FILTERSTAB_FIELDS_SECTION_TITLE}</h6>
            {data?.length === 0 ? (
                <div className="d-flex justify-content-center ptb-120">
                    <span>{APP_CONFIG.NO_FIELDS_FOUND}</span>
                </div>
            ) : (
                    <>
                        {data && data?.map((item, index) => (
                            <div key={index} className="drag-element">
                                <div>
                                    <DragHandle />
                                    {item.label}
                                </div>
                                {/* <img style={{ cursor: 'pointer' }}
                                src="/images/close.svg" alt="close" className="me-2" /> */}
                            </div>
                        ))}
                    </>
                )}
        </div>
    );
}

export default Fields;
