import React from 'react';
import { APP_CONFIG } from '../../utils/StaticData';

const Search = ({ data }) => {

    return (
        <div style={{ flexDirection: "column", alignItems: 'flex-start', display: 'flex' }}>
            <h6 className="mb-4 fw-normal">{APP_CONFIG.SEARCH_FOR_THE_KEYWORD}</h6>
            <input disabled id='Search' type="text" className="mb-3 custom-placeholder form-control rounded-1 h-100" placeholder="Search"
                value={data?.searchText}
            />
            <div className="member">
                <ul className="nav nav-pills mb-3 align-items-center" id="pills-tab" role="tablist">
                    <h6 className="mb-0 text-black fw-normal">{APP_CONFIG.CONDITION_TITLE}</h6>
                    <li className="nav-item" role="presentation">
                        <button
                            style={{ cursor: 'default' }}
                            className={`nav-link ${data?.searchCondition === 'and' ? 'active' : ''}`}
                            type="button"
                        >{APP_CONFIG.AND_BUTTON}</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${data?.searchCondition === 'or' ? 'active' : ''}`}
                            style={{ cursor: 'default' }}
                            type="button"
                        >{APP_CONFIG.OR_BUTTON}</button>
                    </li>
                </ul>
            </div><h6 className="mb-4 fw-normal">{APP_CONFIG.SEARCH_FOR_THE_2ND_KEYWORD}</h6>
            <input disabled id='Search' type="text" className="mb-3 custom-placeholder form-control rounded-1 h-100" placeholder="Search"
                value={data?.searchText2}
            />
        </div >
    );
}

export default Search;
