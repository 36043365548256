import React from "react";
import CookieConsent from "react-cookie-consent";

const Cookie = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="CookieConsent"
      // style={{ background: "#2B373B" }}
      buttonStyle={{}}
      expires={150}
      // debug={true}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
}

export default Cookie;
