import React, { useEffect, useState } from 'react';
import UserRefModal from '../components/Modal';
import ProcessErrorAndRespond from '../components/ProcessErrorAndRespond';
import Loader from '../components/shared/Loader';
import { ListingLoader } from '../components/SkeletonLoader';
import TableViewData from '../components/Table';
import TitleTag from '../components/TitleTag';
import CustomToast from '../components/Toast';
import { TablesKeys } from '../utils/StaticData';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { APP_CONFIG } from '../utils/StaticData';


const Report = () => {
    const [err, setErr] = useState({});
    const [reportsIds, setreportsIDS] = useState([])
    const [tableData, setTableData] = useState([])
    const [uid, setUID] = useState(-1);
    const [uBordSubId, setUBordSubID] = useState(-1);
    const [isLoadingReport, setReportLoader] = useState(false);
    const [isModalVisible, setVisible] = useState(false);
    const [tableHeader, setTableHeader] = useState([])
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('dateLastActivity');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoadingOverlay, setLoaderOverlay] = useState(false);
    const [toast, setToast] = useState(false);
    const [message, setMessage] = useState('');
    const [reportFlag, setReportFlag] = useState({});
    const getReportsID = async () => {
        setLoaderOverlay(true);
        try {
            const res = await NetworkClient.get(BASE_URL + "get-user-subscription-details")
            if (res?.data?.data) {
                setreportsIDS(res?.data?.data)
                if (res?.data?.data?.length == 1) {
                    setUID(res?.data?.data?.[0]?.user_subscription_id)
                    setUBordSubID(res?.data?.data?.[0]?.id)
                    getReport(res?.data?.data?.[0]?.user_subscription_id, res?.data?.data?.[0]?.id)
                    getReportFlag(res?.data?.data?.[0]?.id)
                }
            }
            setLoaderOverlay(false);
        } catch (err) {
            console.log("get-user-subscription-details err", err);
            setLoaderOverlay(false);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const getReport = async (uid, uBordSubId) => {
        setReportLoader(true);
        try {
            const res = await NetworkClient.post(BASE_URL + "user/get-user-filter-pref", {
                unique_board_subscription_id: uBordSubId,
                unique_id: uid,
            })
            // console.log("get-user-filter-pref", res);
            if (res?.data?.code == 401) {
                setErr(res?.data)
                setTimeout(() => {
                    setErr({})
                }, 1000)
            } else if (res?.data?.code == 400) {
                setErr(res?.data)
                setTimeout(() => {
                    setErr({})
                }, 1000)
            }
            else if (res?.data?.code == 200) {
                setTableData(res?.data?.data?.trello_preference)
                // setMasterData(res?.data?.data?.master_records)
                if (res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields?.length > 0 && res?.data?.data?.trello_selected_preference?.userPref?.highiler?.length == 0) {
                    setTableHeader([...res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields])
                } else if (res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields?.length > 0) {
                    setTableHeader([{ id: 101, value: 'strips', label: '' }, ...res?.data?.data?.trello_selected_preference?.userPref?.filter?.fields])
                }
                else {
                    setTableHeader(TablesKeys)
                }
            }
            setReportLoader(false);
        } catch (err) {
            console.log("get-user-filter-pref err", err?.response);
            setReportLoader(false);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }
    const shareReport = async (id) => {
        try {
            const res = await NetworkClient.post(BASE_URL + "set-shareable-flag", {
                unique_board_subscription_id: id
            })
            getReportFlag(id)
            copyTextToClipboard(res.data?.data)
                .then(() => {
                    setToast(true);
                    setMessage(res?.data?.message);
                    setTimeout(() => {
                        setToast(false);
                    }, 10000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log("set-shareable-flag err", err?.response);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const disableShareReport = async (id) => {
        try {
            const res = await NetworkClient.post(BASE_URL + "disable-shareable-id", {
                unique_board_subscription_id: id
            })
            if (res.data)
                getReportFlag(id)
            setToast(true);
            setMessage(res?.data?.message);
            setTimeout(() => {
                setToast(false);
            }, 10000);
        } catch (err) {
            console.log("disable-shareable-id err", err?.response);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const regenerateShareReport = async (uid, uBordSubId) => {
        try {
            const res = await NetworkClient.post(BASE_URL + "regenerate-shareable-id", {
                unique_board_subscription_id: uBordSubId,
                unique_id: uid,
            })
            copyTextToClipboard(res.data?.data)
                .then(() => {
                    setToast(true);
                    setMessage(res?.data?.message);
                    setTimeout(() => {
                        setToast(false);
                    }, 10000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log("regenerate-shareable-id err", err?.response);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    const getReportFlag = async (id) => {
        try {
            const res = await NetworkClient.post(BASE_URL + "shareable-flag-details", {
                unique_board_subscription_id: id
            })
            if (res.data?.code == 200) {
                setReportFlag(res.data.data);
            }
        } catch (err) {
            console.log("shareable-flag-details err", err?.response);
            setErr(err)
            setTimeout(() => {
                setErr({})
            }, 1000)
        }
    }
    useEffect(() => {
        getReportsID()
    }, [])
    // console.log('render',tableHeader);
    return (
        <>
            <TitleTag title={"User Report"} />
            <Loader loader={isLoadingOverlay} />
            <ProcessErrorAndRespond Error={err} />
            <section className="licence-manager min-vh-100 bg-F6F6F6">
                <div className="bg-white">
                    <div className="container">
                        <div className="d-block d-sm-flex justify-content-between align-items-center mb-18 align-items-center pb-3 pt-20 text-center">
                            <div className="d-flex col-12 col-sm-4 col-lg-3 align-items-center">
                                <select
                                    value={uBordSubId}
                                    defaultValue={-1}
                                    onChange={async (e) => {
                                        const currentObj = reportsIds.find((obj) => obj.id === e.target.value)
                                        setUID(currentObj?.user_subscription_id)
                                        setUBordSubID(currentObj?.id)
                                        getReport(currentObj?.user_subscription_id, currentObj?.id)
                                        getReportFlag(currentObj?.id)
                                        setTableData([])
                                        setPage(0)
                                        // setMasterData({})
                                    }}
                                    class="form-select "
                                    aria-label="Default select example">
                                    <option value={-1} disabled selected>{APP_CONFIG.SELECT_REPORTS}</option>
                                    {reportsIds?.map((data) => {
                                        return (
                                            <option value={data?.id}>{data?.board_name}</option>
                                        )
                                    })}
                                </select>
                                {uid !== -1 && uid !== '' && <i onClick={() => {
                                    setTableData([])
                                    // setMasterData({})
                                    setPage(0)
                                    getReport(uid, uBordSubId)
                                    getReportFlag(uBordSubId)
                                }} class="fa fa-refresh ms-2 pointer" aria-hidden="true" />
                                }
                            </div>

                            <div className="py-1">
                                {/* <div class="tooltip"> */}
                                {/* <span class="tooltiptext">Copy</span> */}
                                <button
                                    disabled={uid == -1}
                                    onClick={() => setVisible(true)}
                                    // type="button"
                                    className="btn btn-outline-primary me-3">
                                    {APP_CONFIG.VIEW_FILTERS_BUTTON}
                                </button>
                                {/* </div> */}
                                {
                                    reportFlag?.shareable_flag == 0 ? <>
                                        <button
                                            disabled={uid == -1}
                                            onClick={() => {
                                                shareReport(uBordSubId)
                                            }}
                                            className="btn btn-outline-primary" >
                                            <i class="icofont-share me-2"></i>
                                            {APP_CONFIG.SHARE_REPORTS_BUTTON}
                                        </button>
                                    </>
                                        :
                                        reportFlag?.shareable_flag == 1 && <div class="btn-group">
                                            <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                {APP_CONFIG.SHARE_REPORTS_BUTTON}
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                    <button
                                                        type='button'
                                                        onClick={() => shareReport(uBordSubId)}
                                                        className="dropdown-item">Copy Report URL</button>
                                                </li>
                                                <li>
                                                    <button
                                                        type='button'
                                                        onClick={() => disableShareReport(uBordSubId)}
                                                        className="dropdown-item">Stop Sharing</button>
                                                </li>
                                                <li>
                                                    <button
                                                        type='button'
                                                        onClick={() => regenerateShareReport(uid, uBordSubId)}
                                                        className="dropdown-item">Change Sharing URL</button>
                                                </li>
                                            </ul>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isLoadingReport ? <ListingLoader /> :
                    tableData?.length > 0 && tableHeader?.length > 0 ?
                        <TableViewData
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            tableData={tableData}
                            tableHeader={tableHeader}
                            viewOnly={false}
                            uid={uid}
                        />
                        :
                        <div className="container">
                            <div className="section-title pt-160 pt-0 mb-0">
                                <h4 className="color-737373">No Data found</h4>
                            </div>
                        </div>
                }
            </section>
            <UserRefModal UID={uBordSubId} open={isModalVisible} onClose={() => setVisible(false)} />
            <CustomToast toast={toast} message={message}
                closeToast={() => {
                    setToast(false);
                }} />
        </>
    );
}

export default Report;
