import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../context';
import { APP_CONFIG } from '../../utils/StaticData';


const Pricing = (props) => {
    const Context = useContext(DataContext);
    const [selecteedPrice, setPrice] = useState('')
    const history = useHistory();
    return (
        <section className="shop-details-area ptb-100" ref={props?.reference} id='Pricing'>

            <div className="container">
                <div className="section-title">
                    <h2 className="fs-40 mb-4">{APP_CONFIG.HOME_PRICING_SECTION_TITLE}</h2>
                </div>
                <div className="shop-details shadow-none p-0">

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="shop-details-tabs text-center mt-0">
                                <div id="Pricing" className="Pricing_tabs_item">
                                    <div class="row justify-content-center">
                                        {Context?.products?.map((pricing, i) => {
                                            console.log("pricing", pricing);
                                            return (
                                                <div class="col-xl-4 col-md-6 pb-5 pb-md-0 px-lg-4"
                                                    onClick={() => {
                                                        setPrice(pricing?.price_id)
                                                    }}
                                                    key={i}>
                                                    <div class={`pricing ${selecteedPrice === pricing?.price_id ? "active" : ""} pointer`}>
                                                        <div class="pricing-title-area">
                                                            <h4 class="pricing-title fw-bold">{pricing?.metadata?.PRICE_TITLE ? pricing?.metadata?.PRICE_TITLE : pricing?.interval}</h4>
                                                            <h6 class={`mb-0 fw-bold ${selecteedPrice === pricing?.price_id ? "text-white" : "color-737373"}`}>{pricing?.metadata?.PRICE_SUBTITLE}</h6>
                                                            {pricing?.metadata?.SAVE_PRICE &&
                                                                <span class="badge rounded-pill px-3 py-1 mt-2 fs-6">{pricing?.metadata?.SAVE_PRICE}</span>
                                                            }
                                                        </div>
                                                        <span class="pricing-price">
                                                            <sup>$</sup>
                                                            <strong>{pricing?.unit_amount / 100}</strong>
                                                            Per {pricing?.interval}
                                                        </span>

                                                        <ul class="list-unstyled pricing-list ps-4 text-start">
                                                            {pricing?.trial_period_days &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.trial_period_days} Days Free trial
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_1 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_1}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_2 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_2}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_3 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_3}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_4 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_4}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_5 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_5}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                            {pricing?.metadata?.FEATURE_6 &&
                                                                <li>
                                                                    <div className="d-flex align-items-center ">
                                                                        <img src={`/images/${selecteedPrice === pricing?.price_id ? "tick.png" : "green-tick.png"}`} alt="logo" className="" />
                                                                        <span>
                                                                            {pricing?.metadata?.FEATURE_6}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            }
                                                        </ul>
                                                        <div class="px-4 pt-42">
                                                            {/* <NavLink to={ "/checkout"}   className="d-block"> */}
                                                            <button
                                                                onClick={() => {
                                                                    history.push("/checkout?price_id=" + pricing?.price_id)
                                                                }}
                                                                type="button" className={`btn ${selecteedPrice === pricing?.price_id ? "btn-white" : "btn-primary"} w-100 `}>{APP_CONFIG.BUY_NOW_BUTTON}</button>
                                                            {/* </NavLink> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Pricing;
