import React, { useState } from 'react';
import Select from 'react-select';
import HighlighterCard from '../FilterComponent/HighlighterCard';
import { APP_CONFIG } from '../../utils/StaticData';

const HighlighterTab = ({ highlighter }) => {
    console.log("highlighter", highlighter);
    return (
        <div className="highlitertab scrollable-section p-3">
            {highlighter?.length === 0 ? (
                <div className="d-flex justify-content-center ptb-120">
                    <span>{APP_CONFIG.NO_HIGHLIGHTS_FOUND}</span>
                </div>
            ) : (
                    <>
                        {highlighter?.map((data) => {
                            return <HighlighterCard data={data} />
                        })}
                    </>
                )}
        </div>
    );
}

export default HighlighterTab;
