import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select, { components } from 'react-select';
import { APP_CONFIG } from '../../utils/StaticData';

const HighlighterCard = ({ data }) => {
    const [valueList, setValueList] = useState([]);

    const ColorPicker = ({ value, onChange, ...rest }) => {
        return (
            <div className="color-picker">
                <input disabled style={{ pointerEvents: 'none' }} type="color" value={value} className="mb-0" />
                <input disabled type="text" value={value} className="w-100 border-0 bg-transparent" />
            </div>
        );
    };

    return (
        <>
            <div className="row px-2 align-items-end">
                <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                    <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_FIELD_TITLE} </h6>
                    <Select
                        isDisabled={true}
                        value={data.Field}
                        placeholder="Field"
                    />
                </div>
                <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                    <h6 className="">{APP_CONFIG.CONDITION_TITLE} </h6>
                    <Select
                        isDisabled={true}

                        options={data?.Field?.condition}
                        value={data?.selectedCondition}
                        placeholder="Condition"
                    />
                </div>
                <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                    <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_VALUE_TITLE} </h6>
                    {(data?.Field?.id == 17 || data?.Field?.id == 18 || data?.Field?.id == 19) ?
                        <Select
                            isDisabled={true}
                            options={valueList}
                            value={data?.Value}
                            placeholder="Value"
                        /> : (data?.Field?.id == 20 || data?.Field?.id == 21) ?
                            <div className="position-relative w-100">
                                {data?.selectedCondition.id === 4 ?
                                    <DatePicker
                                        disabled
                                        selectsRange={true}
                                        startDate={data?.Value?.startDate == null ? null : new Date(data?.Value?.startDate)}
                                        endDate={data?.Value?.endDate == null ? null : new Date(data?.Value?.endDate)}
                                        dateFormat="MMM dd, yyyy"
                                    />
                                    : <DatePicker
                                        disabled
                                        selected={data?.Value == null ? null : new Date(data?.Value)}
                                        dateFormat="MMM dd, yyyy"
                                    />
                                }
                                <img src="/static/Calendar.svg" alt="Calendar" className="calendar-icon pe-none" />
                            </div> :
                            (data?.Field?.id == 10 || data?.Field?.id == 27) ?
                                <input disabled id='Value' type="text" placeholder="Value" className="w-100"
                                    value={data.Value}
                                />
                                :
                                <Select
                                    isDisabled={true}
                                    isDisabled={true}
                                    placeholder="Value"
                                />
                    }
                </div>
                <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button style={{
                                border: data?.isColor ? '2px solid #0b0819' : '2px solid #E9E9E9',
                                pointerEvents: 'none'
                            }} className={`nav-link ${data?.isColor ? 'active' : ''}`}
                            >{APP_CONFIG.COLOR_BUTTON}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button style={{
                                border: data?.isColor ? '2px solid #E9E9E9' : '2px solid #0b0819',
                                pointerEvents: 'none'
                            }} className={`nav-link ${data?.isColor ? '' : 'active '}`}
                            >{APP_CONFIG.ICON_BUTTON}</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content mt-4" id="pills-tabContent">
                <div className={`tab-pane fade ${data?.isColor ? 'show active' : ''}`} id="pills-Color" >
                    <div className="row px-2">
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_CELL_COLOR_TITLE} </h6>
                            <ColorPicker value={data?.cellColor} />

                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_CELL_BORDER_TITLE} </h6>
                            <ColorPicker value={data?.cellBorderColor} />

                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_TEXT_COLOR_TITLE} </h6>
                            <ColorPicker value={data?.textColor} />

                        </div>
                    </div>
                </div>
                <div className={`tab-pane fade ${data?.isColor ? '' : 'show active'}`} id="pills-Icon">
                    <div className="row px-2">
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <h6 className="">{APP_CONFIG.HIGHLIGHTSTAB_SELECT_ICON_TITLE} </h6>
                            <Select
                                isDisabled={true}
                                formatOptionLabel={function (data) {
                                    return (
                                        <span><i className={data?.value} /> - <span dangerouslySetInnerHTML={{ __html: data.label }} /></span>
                                    );
                                }}
                                value={data?.selectedIcon}
                                placeholder="Select Icon "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
}

export default HighlighterCard;
