const ServicesData = [
    {
        "img": "images/add.png",
        "title": "Tailored reports",
        "desc": "Powerful filters and highlights to give you just the data you need, in crisp, highlighted report.",
    },
    {
        "img": "images/sync.png",
        "title": "Fully Synced with Trello",
        "desc": "Never miss any updates in your reports. Your reports sync real-time with your Trello boards.",
    },
    {
        "img": "images/share.png",
        "title": "Easy Sharing",
        "desc": "Share the generated report with a single link. Easily create a new link or disable link when you need.",
    },
]

export default ServicesData;
