import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { BASE_URL } from '../utils/globle';
import NetworkClient from '../utils/NetworkClient';
import { NavLink } from 'react-router-dom';
import TitleTag from '../components/TitleTag';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AlertModal from "../components/AlertModal"
import { APP_CONFIG } from '../utils/StaticData';
const ForgotPassword = () => {
    const [model, setModal] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory()

    const Schema = Yup.object().shape({
        email: Yup.string()
            .required("*Required")
            .matches(/^\(?([a-z0-9A-Z._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$)$/, "Not Valid Email"),
    });
    return (
        <>
            <TitleTag title={"Forgot Password"} />
            <section className="forgot-password-area ptb-50 vh-100 bg-F6F6F6">
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        <div className="col-xl-5 col-lg-6 col-md-12">
                            <div className="title">
                                <h2 className="pb-2 mb-0">{APP_CONFIG.FORGOT_PASSWORD_PAGE_TITLE}</h2>
                            </div>
                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={Schema}
                                onSubmit={
                                    async (values, { resetForm }) => {
                                        try {
                                            const res = await NetworkClient.post(BASE_URL + "forgot-password", {
                                                email: values?.email,
                                            })
                                            console.log("res", res.data);
                                            if (res.data.data && res.data.success) {
                                                // history.push('/subscriptions')
                                                console.log("res", res.data.data);
                                                // alert(res.data.message)
                                                setTitle('Success');
                                                setMessage(res?.data?.message)
                                                setModal(true)
                                                resetForm('')
                                            }
                                        } catch (err) {
                                            console.log("err", err?.response);
                                            setTitle('Error !!');
                                            setMessage(err?.response?.data?.data)
                                            setModal(true)

                                        }
                                    }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <>
                                        <div className="forgot-password-form my-4 bg-white">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group mb-0">
                                                        <label>{APP_CONFIG.EMAIL_LABEL}</label>
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoFocus
                                                            onKeyPress={(e) => {
                                                                if (e.code === 'Enter') {
                                                                    handleSubmit()
                                                                }
                                                            }}
                                                            value={values?.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="form-control px-0"
                                                            required={true}
                                                            placeholder="Email" />
                                                        <span className='text-danger'>
                                                            {errors.email && touched.email && errors.email}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center pt-2">
                                            <button
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                                className="btn btn-primary btn-login">
                                                {isSubmitting ? <div className="spinner-border spinner-border-sm text-white" /> :
                                                    <>
                                                        <img src="/images/Send.png" alt="Send" className="me-2 login-png" />
                                                        {APP_CONFIG.SUBMIT_BUTTON}
                                                    </>
                                                }
                                            </button>
                                            <NavLink to="/login">
                                                <h6 className="mb-0 "><img src="/images/lock.png" alt="lock" className="me-2 lock-png" />{APP_CONFIG.WANT_TO_TRY_LOGIN_AGAIN}</h6>
                                            </NavLink>
                                        </div>
                                    </>
                                )}
                            </Formik>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
                <AlertModal
                    isError={title == 'Error !!' ? true : false}
                    isOpen={model}
                    Title={title}
                    message={message}
                    closeModal={() => {
                        setModal(false);
                        setMessage('');
                        setTitle('')
                    }} />
            </section>
        </>
    );
}

export default ForgotPassword;