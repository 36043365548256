import React from 'react';
import { Helmet } from "react-helmet";
const TitleTag = (props) => {
  // console.log("TitleTag => ", props);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {props?.title ?
          <title> {props?.title} | Data Highlighter | Power Up for Trello</title>
          :
          <title> Data Highlighter | Power Up for Trello </title>
        }
      </Helmet>
    </>
  )
}

export default TitleTag;
