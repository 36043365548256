import React, { Component } from 'react';
import { APP_CONFIG } from '../../utils/StaticData';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer-area pb-0">
                <div className="container">

                    <div className="copyright-area mt-0">
                        <div className="row align-items-center">

                            <div className="col-lg-4 col-md-4 ">
                                <div className="logo">
                                    <a href="/">
                                        <img src="/images/footer-logo.svg" style={{ height: '75px' }} alt="logo" />
                                    </a>
                                </div>
                                {/* <h2 className="text-white">Data Highlighter</h2> */}
                            </div>
                            <div className="col-lg-4 col-md-4 text-center my-3">
                                <div className="single-footer-widget mb-0">
                                    <ul className="social-links">
                                        <img src="/images/twitter-outlined.png" alt="twitter-outlined" className="mx-2" />
                                        <img src="/images/facebook-filled.png" alt="facebook-filled" className="mx-2" />
                                        <img src="/images/linkedin-filled.png" alt="linkedin-filled" className="mx-2" />
                                        <a href="mailto:support@datahighlighter.com" className="mx-2">
                                            <img src="/images/mail.svg" alt="Contact Us" style={{ padding: '3px', height: '42px', width: '42px' }} className="" />
                                        </a>
                                    </ul>
                                    <ul className="social-links mt-3">
                                        <a href={'/privacy-policy'} target={'_blank'}
                                            className="text-decoration-none fs-6" style={{ color: '#fff' }}
                                            rel="noreferrer"
                                        >
                                            {APP_CONFIG.PRIVACY_POLICY}
                                        </a>
                                        <span className="mx-3">|</span>
                                        <a href={'/terms-and-conditions'} target={'_blank'}
                                            className="text-decoration-none fs-6" style={{ color: '#fff' }}
                                            rel="noreferrer"
                                        >
                                            {APP_CONFIG.TERMS_SERVICE}
                                        </a>
                                    </ul>

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 text-right copyright-text">
                                <p>{APP_CONFIG.FOOTER_COPYRIGHT}{(new Date().getFullYear())} {APP_CONFIG.DATA_HIGHLIGHTER}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
