import axios from 'axios';


class NetworkClient {
    constructor() {
        let service = axios.create({
            // headers,
        });

        service.interceptors.response.use(this.handleSuccess, this.handleError);

        service.interceptors.request.use(async config => {
            console.log('AXIOS =>>>> Starting Request', config);

            const token = localStorage.getItem('user_token')
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });

        service.interceptors.response.use(response => {
            // console.log('AXIOS =>>>>  Response:', response);
            return response;
        });

        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

  handleError = error => {
      // console.log('network Error', error);
      return Promise.reject(error);
  };

  get(path, headers) {
      console.log('path', path);
      return this.service
          .get(path, {
              headers: headers,
          })
          .then(response => response);
  }

  patch(path, payload, headers) {
      return this.service
          .request({
              method: 'PATCH',
              url: path,
              responseType: 'json',
              data: payload,
              headers: headers,
          })
          .then(response => response);
  }

  post(path, payload, headers) {
      return this.service
          .request({
              method: 'POST',
              url: path,
              responseType: 'json',
              data: payload,
              headers: headers,
          })
          .then(response => response);
  }
  put(path, payload, headers) {
      return this.service
          .request({
              method: 'PUT',
              url: path,
              responseType: 'json',
              data: payload,
              headers: headers,
          })
          .then(response => response);
  }
  postAsFormData(path, payload, headers) {
      return this.service
          .request({
              method: 'POST',
              url: path,
              data: payload,
              headers: headers,
          })
          .then(response => response);
  }
}

export default new NetworkClient();
