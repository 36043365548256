import React from 'react';

const CustomToast = ({ message, closeToast, toast }) => {

    return (
        <div class="position-fixed bottom-0 end-0 p-3">
            <div class={`toast align-items-center text-white custom-toast border-0 fade ${toast ? "show" : "hide"}`} role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                    {message}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={closeToast}></button>
                </div>
            </div>
        </div>
    )
}

export default CustomToast;